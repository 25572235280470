import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import configuration from './configuration.slice';
import questionTopic from '../features/QuestionTopics/questionTopic.slice';
import questions from '../features/Questions/questions.slice';
import toaster from '../Components/Notifications/toaster.slice';

const store = configureStore({
    reducer: {
        configuration,
        questionTopic,
        questions,
        toaster,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
