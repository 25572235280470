import React, { ReactNode } from 'react';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

export interface NavItemProps {
    href: string;
    title: ReactNode;
}

const NavItem = ({ href, title }: NavItemProps) => (
    <Button component={Link} to={href} color="inherit">
        {title}
    </Button>
);

export default NavItem;
