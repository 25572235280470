import React, { FC, ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Form, Formik, FormikHelpers } from 'formik';
import { Button, Divider } from '@material-ui/core';
import Stack from '@material-ui/core/Stack';
import { useAuth0 } from '@auth0/auth0-react';
import { AsyncThunk } from '@reduxjs/toolkit';
import { Guid } from 'guid-typescript';
import { makeStyles } from '@material-ui/styles';
import UIStrings from '../../../../Utils/UIStrings';
import QuestionCatergorySection from './QuestionCatergorySection';
import FormikThumb from '../../../../Components/FormikFormComponents/FormikThumb';
import FormikFileSelector from '../../../../Components/FormikFormComponents/FormikFileSelector';
import FormErrorMessage from './FormErrorMessage';
import FormikTextField from '../../../../Components/FormikFormComponents/FormikTextField';
import NavigateBackButton from '../../../../Components/Custom/NavigateBackButton';
import SectionTitle from '../../../../Components/Custom/SectionTitle';
import { RootState, useAppDispatch } from '../../../../app/store';
import { createQuestion, loadQuestion, updateQuestion, updateRejectedQuestion } from '../../questions.slice';
import { showToaster } from '../../../../Components/Notifications/toaster.slice';
import ConfirmationBlockDialog from '../../../../Components/ConfirmationDialog/ConfirmationBlockDialog';
import { QuestionData } from '../../../../Models/QuestionData';
import { ProcessConflict } from '../../../../Models/processable';
import { getErrorMessage } from '../../../../app/store.utils';
import { QuestionFormModel } from '../../../../Models/QuestionFormModel';
import { mapQuestionFormModelToUpsertQuestionModel, UpsertQuestionModel } from '../../../../Models/UpsertQuestionModel';
import { QuestionType } from '../../../../Models/Configuration';

interface Props {
    /**
     * Initial values for Yup Schema
     */
    initialValues: any;
    /**
     * A Yup Schema or a function that returns a Yup schema
     */
    validationSchema?: any | (() => any);
    /**
     * Answer section child component
     */
    answerSectionComponent: ReactElement<any, any>;
    /**
     * Additional options section child component
     */
    additionalOptionsSectionComponent: ReactElement<any, any>;
}

const useStyles = makeStyles(() => ({
    root: {
        marginTop: 20,
        marginBottom: 20,
    },
}));

export function createSharedInitialQuestionCreationFormValues(): QuestionFormModel {
    return {
        id: Guid.createEmpty().toString(),
        question: '',
        testType: '',
        questionDifficulty: '',
        questionTopic: '',
        questionSubtopic1: '',
        questionSubtopic2: '',
        questionInfo: '',
        answers: [],
        blobFileURI: '',
        originalFilename: '',
        creatorId: '',
        file: undefined,
        verifierId: '',
        status: '',
        rowVersion: 'AA==', // == byte[] {0}
        questionType: QuestionType.Choice,
    };
}

export const QuestionCreationForm: FC<Props> = ({
    initialValues,
    validationSchema,
    answerSectionComponent,
    additionalOptionsSectionComponent,
}: Props) => {
    const history = useHistory();
    const classes = useStyles();
    const configuration = useSelector((state: RootState) => state.configuration);
    const processState = useSelector((state: RootState) => state.questions.processState);
    const dispatch = useAppDispatch();
    const questionTopicTree = configuration.questionTopics;
    const { getAccessTokenSilently } = useAuth0();

    const submitHandlerHelper = async (
        values: UpsertQuestionModel,
        { setSubmitting, resetForm }: FormikHelpers<any>,
        action: AsyncThunk<QuestionData | null, any, {}>,
    ) => {
        const result = await dispatch(action({ getAccessTokenSilently, question: values }));
        setSubmitting(false);
        if (action.fulfilled.match(result)) {
            resetForm();
            dispatch(showToaster(UIStrings.QuestionSavedSuccess, 'success'));
            history.push('/');
        } else {
            const message = getErrorMessage(result.error) || UIStrings.WriteQuestionError;
            dispatch(showToaster(message, 'error'));
        }
    };

    const submitHandler = async (values: QuestionFormModel, helpers: FormikHelpers<any>) => {
        const data = values;
        data.status = 'Pending';
        const upsertData: UpsertQuestionModel = mapQuestionFormModelToUpsertQuestionModel(data);
        if (history.location.pathname === `/questions/edit/${data.id}`) {
            await submitHandlerHelper(upsertData, helpers, updateQuestion);
        } else {
            await submitHandlerHelper(upsertData, helpers, createQuestion);
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, helpers) => submitHandler(values, helpers)}
            validateOnChange={false}
            validateOnBlur={false}
        >
            {(formik) => (
                <Form>
                    <ConfirmationBlockDialog
                        isNavigationBlocked={() => formik.dirty}
                        message={UIStrings.ChangesWouldBeLostWarning}
                    />
                    <Stack spacing={2}>
                        {questionTopicTree.length !== 0 && (
                            <QuestionCatergorySection
                                topicTree={questionTopicTree}
                            />
                        )}
                    </Stack>
                    <SectionTitle title={UIStrings.Question} />
                    <Stack spacing={2}>
                        {additionalOptionsSectionComponent}
                        <FormikTextField
                            name="question"
                            type="TextArea"
                            multiline
                            rows={10}
                            label={UIStrings.Question}
                        />
                    </Stack>
                    <SectionTitle title={UIStrings.AdditionalInformation} />
                    <Stack spacing={2}>
                        <FormikFileSelector name="file" accept="image/png, image/jpeg, video/mp4, audio/wav" />
                        <FormikThumb
                            fileValueName="file"
                            blobURIValueName="blobFileURI"
                            uri={initialValues.blobFileURI}
                            fileMimeType={initialValues.fileMimeType}
                        />
                        <FormikTextField
                            name="questionInfo"
                            type="TextArea"
                            multiline
                            rows={3}
                            label={UIStrings.QuestionInfo}
                        />
                    </Stack>
                    <SectionTitle title={UIStrings.Answers} />
                    {answerSectionComponent}
                    <FormErrorMessage />
                    <Divider classes={{ root: classes.root }} />
                    <Stack direction="row" justifyContent="space-around">
                        {processState !== ProcessConflict && (
                            <Button variant="contained" type="submit">
                                {UIStrings.SubmitYourQuestionButton}
                            </Button>
                        )}
                        {processState === ProcessConflict && (
                            <Button
                                variant="contained"
                                onClick={async () => {
                                    const questionId = formik.values.id;
                                    const result = await dispatch(loadQuestion({ getAccessTokenSilently, questionId }));
                                    if (loadQuestion.fulfilled.match(result)) {
                                        dispatch(updateRejectedQuestion(formik.values));
                                        dispatch(showToaster(UIStrings.QuestionReloadSuccess, 'success'));
                                        formik.resetForm();
                                        history.push(`/questions/${questionId}`);
                                    } else {
                                        let message = UIStrings.ReadQuestionError;
                                        const errorMessage = getErrorMessage(result.error);
                                        if (errorMessage) {
                                            message = `${message}. \n ${errorMessage}`;
                                        }
                                        dispatch(showToaster(message, 'error'));
                                    }
                                }}
                            >
                                {UIStrings.ReloadYourQuestionButton}
                            </Button>
                        )}
                        <NavigateBackButton />
                    </Stack>
                </Form>
            )}
        </Formik>
    );
};
