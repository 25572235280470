import { Box, Card, Grid, InputAdornment, ListItemIcon, Paper, Popover, SvgIcon, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import ListIcon from '@material-ui/icons/List';
import DoneIcon from '@material-ui/icons/Done';
import LinearScale from '@material-ui/icons/LinearScale';
import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/styles';
import UIStrings from '../../../Utils/UIStrings';
import QueryParameterStrings from '../../../Utils/QueryParameterStrings';
import { QuestionType } from '../../../Models/Configuration';
import { RoleProtectedComponent } from '../../../Routing/RoleProtectedComponent';
import Role from '../../../Auth/Role';

interface QuestionListToolbarProps {
    updateQuestionFilter: (filter: string) => void;
}

const useStyles = makeStyles({
    root: {
        height: 30,
        fontSize: 12,
    },
});

function QuestionListToolbar(props: QuestionListToolbarProps): JSX.Element {
    const { updateQuestionFilter } = props;
    const classes = useStyles();

    const history = useHistory();
    const handleNewChoiceClick = () => {
        history.push(`/questions/create?${QueryParameterStrings.QuestionType}=${QuestionType.Choice}`);
    };

    const handleNewExactClick = () => {
        history.push(`/questions/create?${QueryParameterStrings.QuestionType}=${QuestionType.Exact}`);
    };

    const handleNewLikertClick = () => {
        history.push(`/questions/create?${QueryParameterStrings.QuestionType}=${QuestionType.Likert}`);
    };

    const [open, setOpen] = useState(false);
    const anchorRef = useRef<HTMLButtonElement>(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: MouseEvent | TouchEvent) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpen(false);
    };

    return (
        <Grid container spacing={3}>
            <Grid item sm={6} md={2} lg={2}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-star' }}>
                    <RoleProtectedComponent
                        allowedRoles={[Role.ContentCreator]}
                        accessAllowedComponent={
                            <Button
                                className={classes.root}
                                startIcon={<AddIcon />}
                                color="primary"
                                variant="contained"
                                ref={anchorRef}
                                aria-controls={open ? 'menu-list-grow' : undefined}
                                aria-haspopup="true"
                                onClick={handleToggle}
                            >
                                {UIStrings.AddQuestion}
                            </Button>
                        }
                    />
                    <Popover
                        open={open}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem={open} id="menu-list-grow">
                                    <MenuItem onClick={handleNewChoiceClick}>
                                        <ListItemIcon>
                                            <ListIcon fontSize="small" />
                                        </ListItemIcon>
                                        {UIStrings.ChoiceAnswerType}
                                    </MenuItem>
                                    <MenuItem onClick={handleNewExactClick}>
                                        <ListItemIcon>
                                            <DoneIcon />
                                        </ListItemIcon>
                                        {UIStrings.ExactAnswerType}
                                    </MenuItem>
                                    <MenuItem onClick={handleNewLikertClick}>
                                        <ListItemIcon>
                                            <LinearScale />
                                        </ListItemIcon>
                                        {UIStrings.LikertAnswerType}
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Popover>
                </Box>
            </Grid>
            <Grid item sm={6} md={10} lg={10}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Card>
                        <TextField
                            onChange={(e) => {
                                updateQuestionFilter(e.target.value);
                            }}
                            InputProps={{
                                className: classes.root,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SvgIcon fontSize="small" color="action">
                                            <SearchIcon />
                                        </SvgIcon>
                                    </InputAdornment>
                                ),
                            }}
                            placeholder={UIStrings.SearchQuestionPlaceholder}
                            variant="outlined"
                        />
                    </Card>
                </Box>
            </Grid>
        </Grid>
    );
}

export default QuestionListToolbar;
