export default interface HttpError {
    httpCode: number;
    errorMessage: string;
}

export function HttpErrorToString(httpError: HttpError): string {
    return JSON.stringify(httpError);
}

export function StringToHttpError(value: string): HttpError | null {
    try {
        return JSON.parse(value || '');
    } catch (error) {
        return null;
    }
}
