/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ConfigurationClient from '../Clients/ConfigurationClient';
import { Configuration, DefaultConfiguration } from '../Models/Configuration';
import {
    DefaultProcessable,
    Processable,
    ProcessError,
    ProcessFulfilled,
    ProcessIdle,
    ProcessRunning,
} from '../Models/processable';
import { LoadEventName } from '../Models/slice.consts';
import {
    addQuestionTopic,
    deleteQuestionTopic,
    updateQuestionTopic,
} from '../features/QuestionTopics/questionTopics.slice';
import { GetAccessTokenSilently } from '../Clients/ClientBase';
import { deleteNodeById, getTopicPath, isRootNode, updateNode, updateNodes } from '../Models/QuestionTopicTreeNode';
import { TestTypeTopicMap } from '../Models/TestTypeTopicMap';

export const ConfigurationDomain = 'configuration';

export const loadConfiguration = createAsyncThunk(
    `${ConfigurationDomain}/${LoadEventName}`,
    async (getAccessTokenSilently: GetAccessTokenSilently) => {
        const client = new ConfigurationClient(getAccessTokenSilently);
        return await client.getConfiguration();
    },
);

const initialState: Configuration & Processable = { ...DefaultConfiguration, ...DefaultProcessable };

const configuration = createSlice({
    name: ConfigurationDomain,
    initialState,
    reducers: {
        loadConfiguration(state, action) {
            return action.payload;
        },
        resetConfigurationProcessState(state) {
            return {
                ...state,
                processState: ProcessIdle,
            };
        },
    },
    extraReducers: (builder) => {
        // load configuration
        builder.addCase(loadConfiguration.pending, (state, action) => {
            state.processState = ProcessRunning;
        });
        builder.addCase(loadConfiguration.fulfilled, (state, action) => {
            return { ...action.payload, ...{ processState: ProcessFulfilled } };
        });
        builder.addCase(loadConfiguration.rejected, (state, action) => {
            state.processState = ProcessError;
        });

        // add question topic
        builder.addCase(addQuestionTopic.pending, (state, action) => {
            state.processState = ProcessRunning;
        });
        builder.addCase(addQuestionTopic.fulfilled, (state, action) => {
            if (action.payload) {
                const [topic] = action.payload.topics;
                const { testType } = action.payload;
                if (topic) {
                    updateNode(state.questionTopics, topic);
                    const path = getTopicPath(topic);
                    const mapping = state.testTypeTopicMaps.find((x) => x.questionTopicPath === path);
                    if (mapping == null && isRootNode(state.questionTopics, topic.id)) {
                        const newMap: TestTypeTopicMap = {
                            id: state.testTypeTopicMaps.length,
                            testType,
                            questionTopicPath: path,
                        };
                        state.testTypeTopicMaps.push(newMap);
                    }
                }
            }
            state.processState = ProcessFulfilled;
        });
        builder.addCase(addQuestionTopic.rejected, (state, action) => {
            state.processState = ProcessError;
        });

        // update question topic
        builder.addCase(updateQuestionTopic.pending, (state, action) => {
            state.processState = ProcessRunning;
        });
        builder.addCase(updateQuestionTopic.fulfilled, (state, action) => {
            if (action.payload) {
                const [oldTopic, newTopic] = action.payload.topics;
                const { testType } = action.payload;
                updateNodes(state.questionTopics, oldTopic, newTopic);
                const path = getTopicPath(oldTopic);
                const mapping = state.testTypeTopicMaps.find((x) => x.questionTopicPath === path);
                // empty root topic
                if (
                    mapping != null &&
                    newTopic &&
                    isRootNode(state.questionTopics, newTopic?.id) &&
                    newTopic.children?.length === 0
                ) {
                    mapping.testType = testType;
                    mapping.questionTopicPath = getTopicPath(newTopic);
                }
            }
            state.processState = ProcessFulfilled;
        });
        builder.addCase(updateQuestionTopic.rejected, (state, action) => {
            state.processState = ProcessError;
        });

        // delete question topic
        builder.addCase(deleteQuestionTopic.pending, (state, action) => {
            state.processState = ProcessRunning;
        });
        builder.addCase(deleteQuestionTopic.fulfilled, (state, action) => {
            if (action.payload != null) {
                deleteNodeById(state.questionTopics, action.payload);
            }
            state.processState = ProcessFulfilled;
        });
        builder.addCase(deleteQuestionTopic.rejected, (state, action) => {
            state.processState = ProcessError;
        });
    },
});

export const { resetConfigurationProcessState } = configuration.actions;
export default configuration.reducer;
