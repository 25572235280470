import { QuestionData } from '../../Models/QuestionData';

function upsert(data: QuestionData[], payload: QuestionData | null): void {
    if (!payload) {
        return;
    }

    const index = data.findIndex((item) => item.id === payload?.id);
    if (index >= 0 && payload) {
        // eslint-disable-next-line no-param-reassign
        data[index] = payload;
    } else {
        data.unshift(payload);
    }
}

function mergeHelper(data: QuestionData[], payload: QuestionData[]): QuestionData[] {
    const buffer: Map<string, QuestionData> = new Map<string, QuestionData>();
    for (let i = 0; i < data.length; i++) {
        buffer.set(data[i].id, data[i]);
    }
    for (let i = 0; i < payload.length; i++) {
        buffer.set(payload[i].id, payload[i]);
    }
    const result = [];
    // eslint-disable-next-line guard-for-in
    for (const pair of Array.from(buffer.entries())) {
        result.push(pair[1]);
    }
    return result;
}

function merge(data: QuestionData[], payload: QuestionData[]) {
    if (!payload) {
        return data;
    }
    if (data.length === 0) {
        return payload;
    } else {
        return mergeHelper(data, payload);
    }
}

export { merge, upsert };
