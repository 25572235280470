import React, { FC } from 'react';
import { QuestionData } from '../../../../Models/QuestionData';
import ExactAnswerInfo from './ExactAnswerInfo';
import QuestionInfo from './QuestionInfo';

interface Props {
    question: QuestionData;
}
const ExactQuestionInfo: FC<Props> = ({ question }: Props) => (
    <QuestionInfo question={question} answerComponent={<ExactAnswerInfo answers={question.answers} />} />
);

export default ExactQuestionInfo;
