import React, { useState } from 'react';
import { Box, Container } from '@material-ui/core';
import QuestionListResults from './QuestionListResults';
import QuestionListToolbar from './QuestionListToolbar';
import UIStrings from '../../../Utils/UIStrings';
import PageTitle from '../../../Components/PageTitle';

function QuestionsListPage(): JSX.Element {
    const [questionFilter, setQuestionFilter] = useState<string>();

    function updateQuestionFilter(filter: string): void {
        setQuestionFilter(filter);
    }

    return (
        <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}>
            <Container maxWidth={false}>
                <Box sx={{ mt: 0 }}>
                    <QuestionListToolbar updateQuestionFilter={updateQuestionFilter} />
                </Box>
                <PageTitle title={UIStrings.QuestionsPageTitle} />
                <Box sx={{ pt: 3 }}>
                    <QuestionListResults searchValue={questionFilter || ''} />
                </Box>
            </Container>
        </Box>
    );
}

export default QuestionsListPage;
