import React, { useEffect, useState } from 'react';
import { FormikProps } from 'formik';
import { Prompt, useHistory } from 'react-router';
import { Action, Location } from 'history';
import ConfirmationDialogRaw from './confirmationDialogRaw';
import UIStrings from '../../Utils/UIStrings';

interface ConfirmationBlockDialogProps {
    message: string;
    formik?: FormikProps<any>;
    approveText?: string;
    declineText?: string;
    isNavigationBlocked?: (location: Location, action: Action) => boolean | string;
}

function ConfirmationBlockDialog({
    message,
    formik,
    approveText,
    declineText,
    isNavigationBlocked,
}: ConfirmationBlockDialogProps): JSX.Element {
    const [doNavigate, setDoNavigate] = useState<boolean>(false);
    const [nextPossibleLocation, setNextPossibleLocation] = useState<Location | undefined>(undefined);
    const [open, setOpen] = useState<boolean>(false);
    const history = useHistory();

    const isNavigationBlockedHelper = (location: Location, action: Action): boolean | string => {
        if (isNavigationBlocked) {
            return isNavigationBlocked(location, action);
        }
        if (formik) {
            return formik.dirty;
        }
        return false;
    };

    const setupDialog = (location?: Location) => {
        if (location) {
            setNextPossibleLocation(location);
        }
        setDoNavigate(false);
        setOpen(true);
    };

    const messageCallback = (location: Location, action: Action): boolean | string => {
        if (!doNavigate && isNavigationBlockedHelper(location, action)) {
            setupDialog(location);
            return false;
        }
        return true;
    };
    useEffect(() => {
        if (doNavigate && nextPossibleLocation) {
            history.push(nextPossibleLocation);
        }
    }, [doNavigate, nextPossibleLocation]);

    useEffect(() => {
        window.onbeforeunload = () => {
            return true;
        };
        return () => {
            window.onbeforeunload = null;
        };
    });

    const onConfirm = () => {
        setOpen(false);
        setDoNavigate(true);
    };
    const onCancel = () => {
        setOpen(false);
        setDoNavigate(false);
    };
    return (
        <>
            <Prompt message={messageCallback} />
            <ConfirmationDialogRaw
                classes={{ paper: '' }}
                onConfirm={onConfirm}
                onCancel={onCancel}
                open={open}
                approveText={approveText || UIStrings.Ok}
                declineText={declineText || UIStrings.Cancel}
                id={Math.random().toString()}
                keepMounted
                message={message}
            />
        </>
    );
}

export default ConfirmationBlockDialog;
