export default interface QuestionTopicData {
    id: number;
    questionTopicName: string;
    parent?: QuestionTopicData;
    child?: QuestionTopicData;
}

export function reverseTopicTree(leafNode: QuestionTopicData): QuestionTopicData {
    let currentNode = leafNode;
    let result: QuestionTopicData = { id: currentNode.id, questionTopicName: currentNode.questionTopicName };
    while (currentNode.parent) {
        const newParentNode: QuestionTopicData = {
            id: currentNode.parent.id,
            questionTopicName: currentNode.parent.questionTopicName,
        };
        newParentNode.child = result;
        result = newParentNode;
        currentNode = currentNode.parent;
    }

    return result;
}
