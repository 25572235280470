import React from 'react';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { Typography } from '@material-ui/core';
import UIStrings from '../../Utils/UIStrings';

export interface ConfirmationDialogRawProps {
    classes: Record<'paper', string>;
    id: string;
    keepMounted: boolean;
    open: boolean;
    message: string;
    approveText: string;
    declineText: string;
    onConfirm: (value?: any) => void;
    onCancel: () => void;
}

function ConfirmationDialogRaw(props: ConfirmationDialogRawProps) {
    const { onConfirm, onCancel, open, message, approveText, declineText, ...other } = props;

    const handleCancel = () => {
        onCancel();
    };

    const handleConfirm = () => {
        onConfirm();
    };

    return (
        <Dialog disableEscapeKeyDown maxWidth="xs" aria-labelledby="confirmation-dialog-title" open={open} {...other}>
            <DialogTitle id="confirmation-dialog-title">
                <Typography>{UIStrings.Confirmation}</Typography>
            </DialogTitle>
            <DialogContent dividers>
                <Typography>{message}</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} autoFocus color="primary" variant="contained">
                    {declineText}
                </Button>
                <Button onClick={handleConfirm} color="secondary" variant="contained">
                    {approveText}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmationDialogRaw;
