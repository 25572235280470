import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'react-redux';
import { Switch } from 'react-router-dom';
import ProtectedRoute from '../../Routing/ProtectedRoute';
import QuestionListPage from './List/QuestionListPage';
import EditQuestionPage from './Edit/EditQuestionPage';
import CreateQuestionPage from './Add/CreateQuestionPage';
import QuestionInfoPage from './Info/QuestionInfoPage';
import { loadQuestions } from './questions.slice';
import { RootState, useAppDispatch } from '../../app/store';
import UIStrings from '../../Utils/UIStrings';
import { showToaster } from '../../Components/Notifications/toaster.slice';

function QuestionsPage(): JSX.Element {
    const dispatch = useAppDispatch();
    const { getAccessTokenSilently } = useAuth0();
    const questionsCount = useSelector((state: RootState) => state.questions.data.length);

    useEffect(() => {
        const loadQuestionsAsync = async () => {
            if (questionsCount === 0) {
                const result = await dispatch(loadQuestions({ getAccessTokenSilently }));
                if (!loadQuestions.fulfilled.match(result)) {
                    const message = result?.error?.message ? result.error.message : UIStrings.ReadQuestionListError;
                    dispatch(showToaster(message, 'error'));
                }
            }
        };
        loadQuestionsAsync();
    }, []);
    return (
        <Switch>
            <ProtectedRoute exact path="/" component={QuestionListPage} />
            <ProtectedRoute exact path="/questions" component={QuestionListPage} />
            <ProtectedRoute path="/questions/edit/:questionId" component={EditQuestionPage} />
            <ProtectedRoute path="/questions/create" component={CreateQuestionPage} />
            <ProtectedRoute path="/questions/:questionId" component={QuestionInfoPage} />
        </Switch>
    );
}

export default QuestionsPage;
