import { QuestionTopicFormValues } from '../models/QuestionTopicFormValues';
import QuestionTopicTreeNode from '../../../Models/QuestionTopicTreeNode';

function mapQuestionTopicFormValuesToQuestionTopicTreeNode(values: QuestionTopicFormValues): QuestionTopicTreeNode {
    return {
        id: Number(values?.questionTopic?.id) || 0,
        name: values?.questionTopic?.name || '',
        testType: values?.testType || '',
        children:
            values.questionSubtopic1 == null
                ? []
                : [
                      {
                          id: Number(values?.questionSubtopic1?.id) || 0,
                          name: values.questionSubtopic1.name,
                          testType: values?.testType || '',
                          children:
                              values.questionSubtopic2 == null
                                  ? []
                                  : [
                                        {
                                            id: Number(values?.questionSubtopic2?.id) || 0,
                                            name: values.questionSubtopic2.name,
                                            testType: values?.testType || '',
                                            children: [],
                                        },
                                    ],
                      },
                  ],
    };
}

export default mapQuestionTopicFormValuesToQuestionTopicTreeNode;
