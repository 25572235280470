export const serverURL = process.env.REACT_APP_API_SERVER;
export const apiUrl = `${serverURL}/api/`;

export const claimNames = {
    role: process.env.REACT_APP_ROLE_CLAIM ?? '',
};

export const authProviderSettings = {
    domain: process.env.REACT_APP_AUTH_DOMAIN ?? '',
    clientId: process.env.REACT_APP_AUTH_CLIENT_ID ?? '',
    redirectUri: window.location.origin,
    audience: process.env.REACT_APP_AUTH_AUDIENCE ?? '',
    connection_hint: process.env.REACT_APP_AUTH_CONNECTION ?? '',
};

export const ApplicationInsightsSettings = {
    key: process.env.REACT_APP_AI_KEY,
    cloudRole: 'Content Creation App',
};
