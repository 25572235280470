import React, { FC } from 'react';
import { CardContent, Grid } from '@material-ui/core';

import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { getColorFromQuestionStatus, getFullTopicName, QuestionData } from '../../../../Models/QuestionData';
import UIStrings from '../../../../Utils/UIStrings';
import LabeledText from '../../../../Components/Custom/LabeledText';
import displayGuid from '../../../../Utils/displayGuid';
import { QuestionType } from '../../../../Models/Configuration';
import { RootState } from '../../../../app/store';

interface Props {
    question: QuestionData;
}

const useStyles = makeStyles((question: QuestionData) => ({
    root: {
        paddingLeft: 0,
        paddingRight: 0,
    },
}));

const QuestionMeta: FC<Props> = ({ question }: Props) => {
    const classes = useStyles(question);
    const configuration = useSelector((state: RootState) => state.configuration);

    let labelValueDict: { key: string; value: string }[] = [
        { key: `${UIStrings.QuestionIdQuestionMetadata}:`, value: displayGuid(question.id) },
        { key: `${UIStrings.QuestionDifficulty}:`, value: question.questionDifficulty },
        { key: `${UIStrings.QuestionType}:`, value: question.questionType },
        { key: `${UIStrings.TestType}:`, value: question.testType },
        { key: `${UIStrings.QuestionTopic}:`, value: getFullTopicName(question.topic) },
        { key: `${UIStrings.CreatedByQuestionMetadata}:`, value: question.creatorName },
        { key: `${UIStrings.CreationDate}:`, value: question.creationDate },
        { key: `${UIStrings.Verifier}:`, value: question.verifierName ?? '' },
        { key: `${UIStrings.VerificationDate}:`, value: question.verificationDate ?? '' },
    ];

    if (question.questionType === QuestionType.Likert) {
        labelValueDict = [
            ...labelValueDict,
            {
                key: `${UIStrings.LikertAnswerGroupLabel}:`,
                value: configuration.likertAnswerGroups?.filter((group) => group.id === question.likertAnswerGroupId)[0]
                    .groupName,
            },
            {
                key: `${UIStrings.PositivelyCoded}:`,
                value: question.positivelyCoded.toString(),
            },
        ];
    }

    return (
        <CardContent classes={{ root: classes.root }}>
            <Grid container justifyContent="center" spacing={1}>
                {labelValueDict.map((kvp) => {
                    return (
                        <Grid item md={6} xs={12} key={kvp.key}>
                            <LabeledText label={kvp.key} text={kvp.value} />
                        </Grid>
                    );
                })}
                <Grid item md={6} xs={12}>
                    <LabeledText
                        label={UIStrings.Status}
                        text={question.status}
                        textColor={getColorFromQuestionStatus(question)}
                    />
                </Grid>
            </Grid>
        </CardContent>
    );
};

export default QuestionMeta;
