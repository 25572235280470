import React, { FC } from 'react';
import { QuestionData } from '../../../../Models/QuestionData';
import MultipleChoiceAnswerInfos from './MultipleChoiceAnswerInfos';
import QuestionInfo from './QuestionInfo';

interface Props {
    question: QuestionData;
}
const MultipleChoiceQuestionInfo: FC<Props> = ({ question }: Props) => (
    <QuestionInfo question={question} answerComponent={<MultipleChoiceAnswerInfos answers={question.answers} />} />
);

export default MultipleChoiceQuestionInfo;
