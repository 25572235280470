import React, { FC } from 'react';
import { QuestionData } from '../../../../Models/QuestionData';
import UIStrings from '../../../../Utils/UIStrings';

import { QuestionCreationForm } from '../../Add/Components/QuestionCreationForm';
import { createLikertQuestionValidationSchema } from '../../Add/Components/QuestionValidationSchema';
import {
    LikertQuestionFormModel,
    mapQuestionDataToLikertQuestionFormModel,
} from '../../../../Models/QuestionFormModel';
import LikertAnswerContainer from '../../Add/Components/LikertAnswerContainer';
import { FormikCheckbox } from '../../../../Components/FormikFormComponents/FormikCheckbox';

interface Props {
    question: QuestionData;
}

const LikertQuestionUpdateForm: FC<Props> = ({ question }: Props) => {
    const upsertQuestion: LikertQuestionFormModel = mapQuestionDataToLikertQuestionFormModel(question);
    const validationSchema = createLikertQuestionValidationSchema();

    return (
        <QuestionCreationForm
            initialValues={upsertQuestion}
            validationSchema={validationSchema}
            answerSectionComponent={<LikertAnswerContainer />}
            additionalOptionsSectionComponent={
                <FormikCheckbox label={UIStrings.PositivelyCoded} name="positivelyCoded" />
            }
        />
    );
};

export default LikertQuestionUpdateForm;
