import { FieldArray, useFormikContext } from 'formik';
import React, { FC } from 'react';
import { Button, IconButton, Stack } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { Guid } from 'guid-typescript';
import UIStrings from '../../../../Utils/UIStrings';
import { FormikCheckbox } from '../../../../Components/FormikFormComponents/FormikCheckbox';
import FormikTextField from '../../../../Components/FormikFormComponents/FormikTextField';
import { QuestionFormModel } from '../../../../Models/QuestionFormModel';

const MultipleChoiceAnswerContainer: FC = () => {
    const {
        values: { answers },
    } = useFormikContext<QuestionFormModel>();

    return (
        <FieldArray name="answers">
            {({ insert, remove, push }) => (
                <Stack spacing={2} alignItems="flex-start">
                    {answers.length > 0 &&
                        answers.map((answer, index) => (
                            <Stack key={answer.id} direction="row" spacing={1} alignItems="center">
                                <FormikTextField
                                    name={`answers.${index}.content`}
                                    type="Text"
                                    placeholder={`Option ${index + 1}`}
                                />
                                <IconButton
                                    color="primary"
                                    aria-label="remove answer option"
                                    onClick={() => remove(index)}
                                    component="span"
                                >
                                    <DeleteIcon />
                                </IconButton>
                                <FormikCheckbox label={UIStrings.CorrectAnswer} name={`answers.${index}.isCorrect`} />
                            </Stack>
                        ))}
                    <Button
                        startIcon={<AddIcon />}
                        onClick={() => {
                            const newElement = { id: Guid.create().toString(), content: '', correctAnswer: false };
                            push(newElement);
                        }}
                    >
                        {UIStrings.AddAnswerOptionButton}
                    </Button>
                </Stack>
            )}
        </FieldArray>
    );
};

export default MultipleChoiceAnswerContainer;
