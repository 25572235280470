import { get, httpDelete, post, put, toJson } from '../../../Clients/HttpUtils';
import Page, { EmptyPage } from '../../../Models/Page';

import { QuestionTopicModel } from '../models/QuestionTopicModel';
import QuestionTopicTreeNode from '../../../Models/QuestionTopicTreeNode';
import ClientBase from '../../../Clients/ClientBase';
import UIStrings from '../../../Utils/UIStrings';

class QuestionTopicClient extends ClientBase {
    async getQuestionTopics(offset: number = 0): Promise<Page<QuestionTopicModel>> {
        const accessToken = await this.getAccessTokenSilently();
        const result = await get<Page<QuestionTopicModel>>({
            path: this.addHost(`questionTopic?offset=${offset}`),
            accessToken,
        });

        if (result.ok && result.body) {
            return result.body;
        } else {
            return EmptyPage;
        }
    }

    async getQuestionTopicTrees(offset: number = 0): Promise<Page<QuestionTopicTreeNode>> {
        const accessToken = await this.getAccessTokenSilently();
        const result = await get<Page<QuestionTopicTreeNode>>({
            path: this.addHost(`questionTopicTree?offset=${offset}`),
            accessToken,
        });

        if (result.ok && result.body) {
            return result.body;
        } else {
            return EmptyPage;
        }
    }

    async getChildQuestionTopicTree(id: number): Promise<QuestionTopicTreeNode | undefined> {
        const accessToken = await this.getAccessTokenSilently();
        const result = await get<QuestionTopicTreeNode>({
            path: this.addHost(`questionTopicTree/child/${id}`),
            accessToken,
        });

        if (result.ok && result.body) {
            return result.body;
        } else {
            return undefined;
        }
    }

    async addQuestionTopicTree(testType: string, topic: QuestionTopicTreeNode): Promise<QuestionTopicTreeNode | null> {
        if (testType === '' || testType == null) {
            throw new Error(UIStrings.EmptyTestType);
        }
        const str = toJson(topic);
        const accessToken = await this.getAccessTokenSilently();
        const result = await post<QuestionTopicTreeNode>({
            path: this.addHost(`questionTopicTree/type/${testType}`),
            body: str.body,
            contentType: str.contentType,
            accessToken,
        });

        if (result.ok && result.body) {
            return result.body;
        } else {
            return null;
        }
    }

    async updateQuestionTopicTree(
        testType: string,
        topic: QuestionTopicTreeNode,
    ): Promise<QuestionTopicTreeNode | null> {
        if (testType === '' || testType == null) {
            throw new Error(UIStrings.EmptyTestType);
        }
        const str = toJson(topic);
        const accessToken = await this.getAccessTokenSilently();
        const result = await put<QuestionTopicTreeNode>({
            path: this.addHost(`questionTopicTree/type/${testType}`),
            body: str.body,
            contentType: str.contentType,
            accessToken,
        });

        if (result.ok && result.body) {
            return result.body;
        } else {
            return null;
        }
    }

    async deleteQuestionTopicTree(id: number): Promise<boolean | null> {
        const accessToken = await this.getAccessTokenSilently();
        const result = await httpDelete<boolean>({
            path: this.addHost(`questionTopicTree/${id}`),
            accessToken,
        });

        if (result.ok && result.body != null) {
            return result.body;
        } else {
            return null;
        }
    }
}

export default QuestionTopicClient;
