import QuestionTopicTreeNode from '../Models/QuestionTopicTreeNode';
import { OptionValue } from '../Models/OptionValue';

function mapNodeToOption(nodes: QuestionTopicTreeNode[]): OptionValue[] {
    return nodes.map((topicNode) => ({
        id: `${topicNode.id}`,
        name: topicNode.name,
    }));
}

export default mapNodeToOption;
