import React from 'react';
import { useField } from 'formik';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core';
import { SelectProps } from '@material-ui/core/Select/Select';
import { OptionValue } from '../../Models/OptionValue';

type FormikDropdownProps = SelectProps & { name: string; options: OptionValue[]; label?: string; placeholder?: string };

const FormikDropdown = ({ name, options, label, placeholder, ...props }: FormikDropdownProps) => {
    const [field, meta, helpers] = useField(name);
    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };
    return (
        <FormControl fullWidth error={meta.error != null} variant="outlined">
            {placeholder != null && <InputLabel>{label}</InputLabel>}
            <Select
                fullWidth
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                value={field.value ?? ''}
                {...props}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    helpers.setValue(event.target.value);
                }}
                label={label}
            >
                {placeholder != null && <MenuItem value="">{placeholder}</MenuItem>}
                {options.map((option) => (
                    <MenuItem value={option.id} id={`${option.id}`} key={option.id}>
                        {option.name}
                    </MenuItem>
                ))}
            </Select>
            {meta.error != null && <FormHelperText>{meta.error}</FormHelperText>}
        </FormControl>
    );
};

export default FormikDropdown;
