import React from 'react';

import { createFilterOptions, FilterOptionsState, FormControl, FormHelperText, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useField } from 'formik';
import { OptionValue } from '../../Models/OptionValue';

type FormikAutocompleteProps = { name: string; options: OptionValue[] };

const filter = createFilterOptions<OptionValue>();

const filterOptions = (options: OptionValue[], params: FilterOptionsState<OptionValue>) => {
    return filter(options, params);
};

const FormikAutoComplete = ({ name, options }: FormikAutocompleteProps) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [field, meta, helpers] = useField(name);

    return (
        <FormControl fullWidth error={meta.error != null} variant="outlined">
            <Autocomplete
                freeSolo
                options={options}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                    <TextField {...params} variant="outlined" value={field.value} error={meta.error != null} />
                )}
                onChange={(event, newValue) => {
                    helpers.setValue(newValue);
                }}
                onInputChange={(event, value) => {
                    helpers.setValue({ id: meta.value.id, name: value });
                }}
                filterOptions={filterOptions}
                contentEditable={false}
            />
            {meta.error != null && <FormHelperText>{meta.error}</FormHelperText>}
        </FormControl>
    );
};

export default FormikAutoComplete;
