/* eslint-disable jsx-a11y/media-has-caption */
import React, { FC, ReactElement } from 'react';
import { Box, Stack } from '@material-ui/core';
import { useAuth0 } from '@auth0/auth0-react';
import QuestionMeta from './QuestionMeta';
import UIStrings from '../../../../Utils/UIStrings';
import { RoleProtectedComponent } from '../../../../Routing/RoleProtectedComponent';
import Role from '../../../../Auth/Role';
import { QuestionData } from '../../../../Models/QuestionData';
import QuestionManagementPanel from './QuestionManagementPanel';
import LabeledText from '../../../../Components/Custom/LabeledText';
import SectionTitle from '../../../../Components/Custom/SectionTitle';
import FilePreview from '../../../../Components/Styled/FilePreview';

interface Props {
    question: QuestionData;
    answerComponent: ReactElement<any, any>;
}

const QuestionInfo: FC<Props> = ({ question, answerComponent }: Props) => {
    const { user } = useAuth0();

    return (
        <Box>
            <Stack spacing={3}>
                <LabeledText label={UIStrings.Question} text={question.question} />
                {question?.blobFileURI !== null && question?.fileMimeType !== null && (
                    <FilePreview content={question.blobFileURI} fileMimeType={question.fileMimeType} />
                )}
                {question.questionInfo && <LabeledText label={UIStrings.QuestionInfo} text={question.questionInfo} />}
                {answerComponent}
                {question?.status === 'Rejected' && (
                    <LabeledText label={UIStrings.Comment} text={question.rejectionComment} />
                )}
            </Stack>

            <SectionTitle title={UIStrings.MetaData} />
            <QuestionMeta question={question} />
            {question?.status === 'Pending' && (
                <RoleProtectedComponent
                    allowedRoles={[Role.ContentVerifier]}
                    accessPredicate={() => user?.sub === question?.verifierId}
                    accessAllowedComponent={
                        <>
                            <SectionTitle title={UIStrings.Actions} />
                            <QuestionManagementPanel questionId={question.id} />
                        </>
                    }
                />
            )}
        </Box>
    );
};

export default QuestionInfo;
