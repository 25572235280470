import { createSlice } from '@reduxjs/toolkit';
import { OptionValue } from '../../Models/OptionValue';
import { QuestionTopicDomain } from '../../Models/QuestionTopicTreeNode';

const initialState: OptionValue[] = [];

const questionTopic = createSlice({
    name: QuestionTopicDomain,
    initialState,
    reducers: {
        setQuestionTopicValues(state, action) {
            localStorage.setItem('questionTopic', JSON.stringify(action.payload));
            return action.payload;
        },
    },
});

export const { setQuestionTopicValues } = questionTopic.actions;

export default questionTopic.reducer;
