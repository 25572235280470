import React from 'react';
import { useField } from 'formik';
import { CheckboxProps, FormControlLabel } from '@material-ui/core';
import GreenCheckbox from '../Custom/GreenCheckbox';

type FormikCheckboxProps = CheckboxProps & { name: string; label: string };

export const FormikCheckbox = ({ name, label, ...props }: FormikCheckboxProps) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [field, meta, helpers] = useField(name);
    return (
        <FormControlLabel
            label={label}
            control={
                <GreenCheckbox
                    {...props}
                    checked={field.value ?? false}
                    onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                        helpers.setValue(checked);
                    }}
                />
            }
        />
    );
};

export default FormikCheckbox;
