import React, { FC } from 'react';
import UIStrings from '../../../../Utils/UIStrings';
import FormikDropdown from '../../../../Components/FormikFormComponents/FormikDropdown';
import { OptionValue } from '../../../../Models/OptionValue';
import { QuestionDifficulties } from '../../../../Models/Configuration';

const DifficultySection: FC = () => {
    const questionDifficultyDropdownOptions: OptionValue[] = QuestionDifficulties;

    return (
        <FormikDropdown
            label={UIStrings.QuestionDifficulty}
            name="questionDifficulty"
            options={questionDifficultyDropdownOptions}
            placeholder={UIStrings.SelectQuestionDifficultyPlaceholder}
        />
    );
};

export default DifficultySection;
