import React from 'react';
import { AppBar, Toolbar, AppBarProps, Box } from '@material-ui/core';
import RightNavBarItemGroup from './RightNavBarItemGroup';
import LeftNavBarItemGroup from './LeftNavBarItemGroup';
import Logo from './Logo';

function MainNavbar(props: JSX.IntrinsicAttributes & AppBarProps) {
    return (
        <AppBar elevation={0} {...props}>
            <Toolbar>
                <Logo />
                <LeftNavBarItemGroup />
                <Box sx={{ flexGrow: 1 }} />
                <RightNavBarItemGroup />
            </Toolbar>
        </AppBar>
    );
}

export default MainNavbar;
