import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { Redirect, Route, Switch } from 'react-router-dom';

import { styled, ThemeProvider } from '@material-ui/core';

import { useDispatch } from 'react-redux';
import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import NotFoundPage from '../Pages/NotFoundPage';
import LoadingOverlay from '../Components/LoadingOverlay';
import Role from '../Auth/Role';
import QuestionTopicsPage from '../features/QuestionTopics/QuestionTopicsPage';
import QuestionsPage from '../features/Questions/QuestionsPage';
import GlobalStyles from '../Components/GlobalStyles';
import MainNavbar from '../Components/NavBar/MainNavbar';
import ProtectedRoute from '../Routing/ProtectedRoute';
import theme from '../theme/theme';
import { loadConfiguration } from './configuration.slice';
import { reactPlugin } from '../Telemetry/AppInsights';

const MainLayoutRoot = styled('div')(() => ({
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
}));

const MainLayoutWrapper = styled('div')({
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
});

const MainLayoutContainer = styled('div')({
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
});

const MainLayoutContent = styled('div')({
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
});

export default function App(): JSX.Element {
    const { isLoading, getAccessTokenSilently } = useAuth0();
    const dispatch = useDispatch();
    useEffect(() => {
        if (!isLoading) {
            dispatch(loadConfiguration(getAccessTokenSilently));
        }
    }, [isLoading]);

    return (
        <AppInsightsErrorBoundary
            appInsights={reactPlugin}
            onError={() => <h1>I believe something went wrong with content-creation-app</h1>}
        >
            <ThemeProvider theme={theme}>
                <GlobalStyles />
                {isLoading ? (
                    <LoadingOverlay />
                ) : (
                    <MainLayoutRoot>
                        <MainNavbar />
                        <MainLayoutWrapper>
                            <MainLayoutContainer>
                                <MainLayoutContent>
                                    <Switch>
                                        <Redirect from="/home" to="/" />
                                        <ProtectedRoute exact path="/" component={QuestionsPage} />
                                        <ProtectedRoute path="/questions" component={QuestionsPage} />
                                        <ProtectedRoute
                                            allowedRoles={[Role.ContentAdmin]}
                                            path="/question-topics"
                                            component={QuestionTopicsPage}
                                        />
                                        <Route component={NotFoundPage} />
                                    </Switch>
                                </MainLayoutContent>
                            </MainLayoutContainer>
                        </MainLayoutWrapper>
                    </MainLayoutRoot>
                )}
            </ThemeProvider>
        </AppInsightsErrorBoundary>
    );
}
