import React, { useRef, useState } from 'react';
import { AccountCircle, Create, ExitToApp, Person, SupervisorAccount, VerifiedUser } from '@material-ui/icons';
import { Box, IconButton, ListItemIcon, Paper, Popover } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import { useAuth0, LogoutOptions } from '@auth0/auth0-react';
import UIStrings from '../../Utils/UIStrings';
import Role from '../../Auth/Role';
import compareArrays from '../../services/CompareArrays';
import { authProviderSettings, claimNames } from '../../AppSettings';
import LoginNavButton from './LoginNavButton';

function UserAccountButton(): JSX.Element {
    const { isAuthenticated, user, logout } = useAuth0();
    let userName = '';
    let userRoles = [];
    if (isAuthenticated && user != null) {
        userName = user?.name?.split('@')[0] ?? '';
        userRoles = user[claimNames.role];
    }
    const [open, setOpen] = useState(false);
    const anchorRef = useRef<HTMLButtonElement>(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: MouseEvent | TouchEvent) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpen(false);
    };

    return (
        <Box sx={{ display: 'flex', justifyContent: 'flex-star' }}>
            {isAuthenticated ? (
                <>
                    <IconButton
                        color="inherit"
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}
                    >
                        {compareArrays([Role.ContentAdmin], userRoles) && <SupervisorAccount />}
                        {compareArrays([Role.ContentCreator], userRoles) && <Create />}
                        {compareArrays([Role.ContentVerifier], userRoles) && <VerifiedUser />}
                        {userRoles.length > 1 && <AccountCircle />}
                    </IconButton>
                    <Popover
                        open={open}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem={open} id="menu-list-grow">
                                    <MenuItem disabled>
                                        <ListItemIcon>
                                            <Person />
                                        </ListItemIcon>
                                        {userName}
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => {
                                            const logoutOptions: LogoutOptions = {
                                                client_id: authProviderSettings.clientId,
                                                // eslint-disable-next-line no-restricted-globals
                                                returnTo: location.origin,
                                            };
                                            logout(logoutOptions);
                                        }}
                                    >
                                        <ListItemIcon>
                                            <ExitToApp />
                                        </ListItemIcon>
                                        {UIStrings.Logout}
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Popover>
                </>
            ) : (
                <LoginNavButton />
            )}
        </Box>
    );
}

export default UserAccountButton;
