import React, { FC } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import UIStrings from '../../Utils/UIStrings';
import { NavButton } from './NavButton';

const LoginNavButton: FC = () => {
    const { loginWithRedirect } = useAuth0();
    return (
        <NavButton
            onClick={() => {
                loginWithRedirect();
            }}
        >
            {UIStrings.SingIn}
        </NavButton>
    );
};

export default LoginNavButton;
