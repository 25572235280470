import compareArrays from '../services/CompareArrays';
import { OptionValue } from './OptionValue';

export default interface QuestionTopicTreeNode {
    id: number;
    name: string;
    testType: string;
    children: QuestionTopicTreeNode[];
}

export const DefaultQuestionTopicTreeNode: QuestionTopicTreeNode = {
    id: 0,
    name: '',
    testType: '',
    children: [],
};

export const QuestionTopicDomain = 'questionTopic';

export function findNodeById(topics: QuestionTopicTreeNode[], topicId: number): QuestionTopicTreeNode | null {
    if (topics.length === 0) {
        return null;
    }

    for (let i = 0; i < topics.length; i++) {
        const topic = topics[i];
        if (topic.id === topicId) {
            return topic;
        }

        const result = findNodeById(topic.children, topicId);
        if (result != null) {
            return result;
        }
    }
    return null;
}

export function findTreeNodeById(topics: QuestionTopicTreeNode[], topicId: number): QuestionTopicTreeNode | null {
    if (topics.length === 0) {
        return null;
    }
    for (let i = 0; i < topics.length; i++) {
        const topic = topics[i];
        if (topic.id === topicId) {
            return topic;
        }

        const parent = copyNode(topic);

        if (findTreeNodeByIdInternal(topic.children, topicId, parent)) {
            return parent;
        }
    }
    return null;
}

function findTreeNodeByIdInternal(
    topics: QuestionTopicTreeNode[],
    topicId: number,
    parent: QuestionTopicTreeNode,
): boolean {
    if (topics.length === 0) {
        return false;
    }

    for (let i = 0; i < topics.length; i++) {
        const topic = topics[i];
        parent.children.push(copyNode(topic));
        if (topic.id === topicId) {
            return true;
        }

        if (findTreeNodeByIdInternal(topic.children, topicId, parent.children[0])) {
            return true;
        }
        parent.children.splice(0, parent.children.length);
    }

    parent.children.splice(0, parent.children.length);

    return false;
}

function copyNode(node: QuestionTopicTreeNode): QuestionTopicTreeNode {
    return {
        id: node.id,
        name: node.name,
        testType: node.testType,
        children: [],
    };
}

export function deleteNodeById(topics: QuestionTopicTreeNode[], topicId: number): boolean {
    if (topics.length === 0) {
        return false;
    }

    for (let i = 0; i < topics.length; i++) {
        const topic = topics[i];
        if (topic.id === topicId) {
            topics.splice(i, 1);
            return true;
        }

        if (deleteNodeById(topic.children, topicId)) {
            return true;
        }
    }

    return false;
}

export function updateNodes(
    topics: QuestionTopicTreeNode[],
    oldTopic: QuestionTopicTreeNode | null,
    newTopic: QuestionTopicTreeNode | null,
) {
    if (oldTopic == null && newTopic == null) {
        return;
    }

    if (oldTopic && newTopic && compareNodeByIds(oldTopic, newTopic)) {
        updateNode(topics, newTopic);
        return;
    }

    if (oldTopic == null) {
        updateNode(topics, newTopic || DefaultQuestionTopicTreeNode);
        return;
    }

    if (newTopic != null) {
        // check if node was moved
        if (compareNodeByIds(oldTopic, newTopic)) {
            // only renaming
            updateNode(topics, newTopic);
        } else {
            const idsOld = getTopicsIds(oldTopic);
            const topicId = idsOld[idsOld.length - 1];

            // delete old
            deleteNodeById(topics, topicId);

            // add new
            updateNode(topics, newTopic);
        }
    }
}

export function updateNode(topics: QuestionTopicTreeNode[], topic: QuestionTopicTreeNode) {
    const existedTopic = findNodeById(topics, topic.id);
    if (existedTopic) {
        existedTopic.name = topic.name;
        if (topic.children.length > 0) {
            updateNode(existedTopic.children, topic.children[0]);
        }
    } else {
        topics.unshift(topic);
    }
}

export function compareNodeByIds(a: QuestionTopicTreeNode, b: QuestionTopicTreeNode): boolean {
    const idA = getTopicsIds(a);
    const idB = getTopicsIds(b);

    return compareArrays(idA, idB);
}

export function getTopicsIds(node: QuestionTopicTreeNode): number[] {
    const subTopic1 = node.children.length > 0 ? node.children[0] : DefaultQuestionTopicTreeNode;
    const subTopic2 = subTopic1.children.length > 0 ? subTopic1.children[0] : DefaultQuestionTopicTreeNode;

    return [node.id, subTopic1.id, subTopic2.id];
}

export function getTopicPath(node: QuestionTopicTreeNode | null): string {
    if (node == null) {
        return '';
    }
    const subTopic1 = node.children.length > 0 ? node.children[0] : DefaultQuestionTopicTreeNode;
    const subTopic2 = subTopic1.children.length > 0 ? subTopic1.children[0] : DefaultQuestionTopicTreeNode;

    const path = [node.name, subTopic1.name, subTopic2.name].filter((x) => x !== '').join('\\');

    return path.substr(0, path.length - 1);
}

export function isRootNode(topics: QuestionTopicTreeNode[], topicId: number): boolean {
    return topics.find((node) => node.id === topicId) != null;
}

export function copyNodeByLevel(node: QuestionTopicTreeNode | null, level: number): QuestionTopicTreeNode | null {
    if (node == null) {
        return null;
    }
    const newNode = copyNode(node);
    if (level > 1 && node.children.length > 0) {
        newNode.children.push(node.children[0]);
    }

    if (level > 2 && node.children.length > 0 && node.children[0].children.length > 0) {
        newNode.children[0].children = [node.children[0].children[0]];
    }

    return newNode;
}

export function isNodeEqualOption(node: QuestionTopicTreeNode, option: OptionValue | undefined): boolean {
    return node.id.toString() === option?.id?.toString();
}
