import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import NavItem from './NavItem';
import ProtectedNavItem from '../ProtectedNavItem';
import UIStrings from '../../Utils/UIStrings';
import Role from '../../Auth/Role';

const LeftNavBarItemGroup: FC = () => {
    return (
        <>
            <Box sx={{ padding: 1 }}>
                <NavItem href="/questions" title={UIStrings.Questions} />
            </Box>
            <Box sx={{ padding: 1 }}>
                <ProtectedNavItem
                    href="/question-topics"
                    title={UIStrings.QuestionTopics}
                    allowedRoles={[Role.ContentAdmin]}
                />
            </Box>
        </>
    );
};

export default LeftNavBarItemGroup;
