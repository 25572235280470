import QuestionTopicTreeNode from './QuestionTopicTreeNode';
import UserProfile from './UserProfile';
import { TestTypeTopicMap } from './TestTypeTopicMap';
import { OptionValue } from './OptionValue';
import { LikertAnswerGroupData } from './LikertAnswerGroupData';

export type Status = 'Pending' | 'Approved' | 'Rejected' | '';
export const Statuses: OptionValue[] = [
    { id: 'Pending', name: 'Pending' },
    { id: 'Approved', name: 'Approved' },
    { id: 'Rejected', name: 'Rejected' },
];

export type QuestionDifficulty = 'Standard' | 'Hard' | '';
export const QuestionDifficulties: OptionValue[] = [
    { id: 'Standard', name: 'Standard' },
    { id: 'Hard', name: 'Hard' },
];

export type TestType = 'Skills' | 'Aptitude' | 'Personality' | 'Leadership' | '';
export const TestTypeValues: OptionValue[] = [
    { id: 'Skills', name: 'Skills' },
    { id: 'Aptitude', name: 'Aptitude' },
    { id: 'Personality', name: 'Personality' },
    { id: 'Leadership', name: 'Leadership' },
];

export enum QuestionType {
    Choice = 'Choice',
    Exact = 'Exact',
    Likert = 'Likert',
}

export interface Configuration {
    testTypeTopicMaps: TestTypeTopicMap[];
    questionTopics: QuestionTopicTreeNode[];
    contentVerifiers: UserProfile[];
    likertAnswerGroups: LikertAnswerGroupData[];
}

export const DefaultConfiguration: Configuration = {
    testTypeTopicMaps: [],
    questionTopics: [],
    contentVerifiers: [],
    likertAnswerGroups: [],
};
