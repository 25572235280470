import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AlertColor } from '@material-ui/core';

export interface ToasterState {
    open: boolean;
    message?: string;
    severity?: AlertColor;
}
const initialState: ToasterState = {
    open: false,
    message: undefined,
    severity: undefined,
};

const toasterSlice = createSlice({
    name: 'toaster',
    initialState,
    reducers: {
        manage(state: ToasterState, action: PayloadAction<ToasterState>) {
            return { ...state, ...action.payload };
        },
    },
});

export function showToaster(message: string, severity: AlertColor) {
    return toasterSlice.actions.manage({
        open: true,
        message,
        severity,
    });
}
export function closeToaster() {
    return toasterSlice.actions.manage({ open: false });
}

export default toasterSlice.reducer;
