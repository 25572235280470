import { useFormikContext } from 'formik';
import React, { FC } from 'react';

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { InputLabel, Typography } from '@material-ui/core';
import { UpsertQuestionModel } from '../../../../Models/UpsertQuestionModel';

const FormErrorMessage: FC = () => {
    const {
        errors: { answers },
    } = useFormikContext<UpsertQuestionModel>();

    return typeof answers === 'string' ? (
        <div
            css={css`
                margin: 10px 0px 10px 0px;
            `}
        >
            <InputLabel error={answers != null} shrink>
                <Typography variant="h4">{answers}</Typography>
            </InputLabel>
        </div>
    ) : null;
};

export default FormErrorMessage;
