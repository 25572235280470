import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/styles';

interface Props {
    title: string;
}

const useStyles = makeStyles({
    uppercase: {
        textTransform: 'uppercase',
    },
});

function PageTitle({ title }: Props) {
    const classes = useStyles();
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Typography className={classes.uppercase} variant="h3" gutterBottom>
                {title}
            </Typography>
        </Box>
    );
}

export default PageTitle;
