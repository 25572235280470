import { Divider, Theme } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/styles';

interface Props {
    title: string;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        fontFamily: theme.typography.fontFamily,
        marginTop: 30,
        marginBottom: 10,
        fontSize: theme.typography.subtitle1.fontSize,
    },
}));

function SectionTitle({ title }: Props) {
    const classes = useStyles();
    return <Divider classes={{ root: classes.root }}>{title}</Divider>;
}

export default SectionTitle;
