/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { QuestionData } from '../../Models/QuestionData';
import { LoadEventName, UpdateEventName, AddEventName } from '../../Models/slice.consts';
import { GetAccessTokenSilently } from '../../Clients/ClientBase';
import {
    DefaultProcessable,
    Processable,
    ProcessError,
    ProcessFulfilled,
    ProcessIdle,
    ProcessRunning,
} from '../../Models/processable';
import Page, { EmptyPage } from '../../Models/Page';
import QuestionClient from '../../Clients/QuestionClient';
import { toFormData } from '../../Clients/HttpUtils';
import { UpsertQuestionModel } from '../../Models/UpsertQuestionModel';
import { RootState } from '../../app/store';
import { merge, upsert } from './questions.slice.utils';
import { getProcessingState } from '../../app/store.utils';

type QuestionState = (Page<QuestionData> | null) & Processable;

const QuestionDomain = 'question';
const QuestionsDomain = `${QuestionDomain}s`;
const ApproveEventName = 'Approve';
const RejectEventName = 'Reject';

const initialState: QuestionState = { ...EmptyPage, ...DefaultProcessable };

export const loadQuestions = createAsyncThunk<
    Page<QuestionData>,
    { getAccessTokenSilently: GetAccessTokenSilently; offset?: number }
>(`${QuestionsDomain}/${LoadEventName}`, async (api) => {
    const client = new QuestionClient(api.getAccessTokenSilently);
    return await client.getQuestions(api.offset);
});

export const createQuestion = createAsyncThunk<
    QuestionData | null,
    { getAccessTokenSilently: GetAccessTokenSilently; question: UpsertQuestionModel }
>(`${QuestionDomain}/${AddEventName}`, async (api) => {
    const client = new QuestionClient(api.getAccessTokenSilently);
    return await client.createQuestion(api.question, toFormData);
});

export const loadQuestion = createAsyncThunk<
    QuestionData | null,
    { getAccessTokenSilently: GetAccessTokenSilently; questionId: string }
>(`${QuestionDomain}/${LoadEventName}`, async (api) => {
    const client = new QuestionClient(api.getAccessTokenSilently);
    return await client.getQuestion(api.questionId);
});

export const updateQuestion = createAsyncThunk<
    QuestionData | null,
    { getAccessTokenSilently: GetAccessTokenSilently; question: UpsertQuestionModel }
>(`${QuestionDomain}/${UpdateEventName}`, async (api) => {
    const client = new QuestionClient(api.getAccessTokenSilently);
    return await client.updateQuestion(api.question, toFormData);
});

export const approveQuestion = createAsyncThunk<
    QuestionData | null,
    { getAccessTokenSilently: GetAccessTokenSilently; questionId: string }
>(`${QuestionDomain}/${ApproveEventName}`, async (api) => {
    const client = new QuestionClient(api.getAccessTokenSilently);
    return await client.approveQuestion(api.questionId);
});

export const rejectQuestion = createAsyncThunk<
    QuestionData | null,
    { getAccessTokenSilently: GetAccessTokenSilently; questionId: string; rejectionComment: string }
>(`${QuestionDomain}/${RejectEventName}`, async (api) => {
    const client = new QuestionClient(api.getAccessTokenSilently);
    return await client.rejectQuestion(api.questionId, api.rejectionComment);
});

const questionsSlice = createSlice({
    name: QuestionsDomain,
    initialState,
    reducers: {
        resetProcessStatus(state: QuestionState) {
            return {
                ...state,
                processState: ProcessIdle,
            };
        },
        updateRejectedQuestion(state: QuestionState, action) {
            const index = state.data.findIndex((x) => x.id === action.payload.id);

            if (index >= 0) {
                const question = state.data[index];
                if (question.status === 'Rejected' && action.payload.status === 'Pending') {
                    action.payload.rowVersion = question.rowVersion;
                    action.payload.status = question.status;
                    action.payload.rejectionComment = question.rejectionComment;
                    state.data[index] = { ...question, ...action.payload };
                }
            }
            return state;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadQuestions.pending, (state, action) => {
                state.processState = ProcessRunning;
            })
            .addCase(loadQuestions.fulfilled, (state, action) => {
                const list = merge(state.data, action.payload.data);
                return { ...state, data: list, processState: ProcessFulfilled };
            })
            .addCase(loadQuestions.rejected, (state, action) => {
                state.processState = ProcessError;
            });
        builder
            .addCase(updateQuestion.pending, (state, action) => {
                state.processState = ProcessRunning;
            })
            .addCase(updateQuestion.fulfilled, (state, action) => {
                upsert(state.data, action.payload);
                state.processState = ProcessFulfilled;
            })
            .addCase(updateQuestion.rejected, (state, action) => {
                state.processState = getProcessingState(action.error);
            });
        builder
            .addCase(loadQuestion.pending, (state, action) => {
                state.processState = ProcessRunning;
            })
            .addCase(loadQuestion.fulfilled, (state, action) => {
                upsert(state.data, action.payload);
                state.processState = ProcessFulfilled;
            })
            .addCase(loadQuestion.rejected, (state, action) => {
                state.processState = ProcessError;
            });
        builder
            .addCase(createQuestion.pending, (state, action) => {
                state.processState = ProcessRunning;
            })
            .addCase(createQuestion.fulfilled, (state, action) => {
                if (action.payload) {
                    state.data.unshift(action.payload);
                }
                state.processState = ProcessFulfilled;
            })
            .addCase(createQuestion.rejected, (state, action) => {
                state.processState = ProcessError;
            });
        builder
            .addCase(approveQuestion.pending, (state, action) => {
                state.processState = ProcessRunning;
            })
            .addCase(approveQuestion.fulfilled, (state, action) => {
                upsert(state.data, action.payload);
                state.processState = ProcessFulfilled;
            })
            .addCase(approveQuestion.rejected, (state, action) => {
                state.processState = ProcessError;
            });
        builder
            .addCase(rejectQuestion.pending, (state, action) => {
                state.processState = ProcessRunning;
            })
            .addCase(rejectQuestion.fulfilled, (state, action) => {
                upsert(state.data, action.payload);
                state.processState = ProcessFulfilled;
            })
            .addCase(rejectQuestion.rejected, (state, action) => {
                state.processState = ProcessError;
            });
    },
});

export const selectQuestionById = (state: RootState, id: string) => state.questions.data.find((item) => item.id === id);
export default questionsSlice.reducer;

export const { updateRejectedQuestion } = questionsSlice.actions;
