import React, { FC } from 'react';
import { createSharedInitialQuestionCreationFormValues, QuestionCreationForm } from './QuestionCreationForm';
import { createLikertQuestionValidationSchema } from './QuestionValidationSchema';
import LikertAnswerContainer from './LikertAnswerContainer';
import { FormikCheckbox } from '../../../../Components/FormikFormComponents/FormikCheckbox';
import UIStrings from '../../../../Utils/UIStrings';
import { QuestionType, QuestionDifficulty } from '../../../../Models/Configuration';
import { LikertQuestionFormModel } from '../../../../Models/QuestionFormModel';

const LikertQuestionCreationForm: FC = () => {
    const sharedInitialValues = createSharedInitialQuestionCreationFormValues();
    const standartDifficulty: QuestionDifficulty = 'Standard';
    const initialValues: LikertQuestionFormModel = {
        ...sharedInitialValues,
        questionDifficulty: standartDifficulty,
        likertAnswerGroupId: '',
        positivelyCoded: true,
        questionType: QuestionType.Likert,
    };

    const validationSchema = createLikertQuestionValidationSchema();

    return (
        <QuestionCreationForm
            initialValues={initialValues}
            validationSchema={validationSchema}
            answerSectionComponent={<LikertAnswerContainer />}
            additionalOptionsSectionComponent={
                <FormikCheckbox label={UIStrings.PositivelyCoded} name="positivelyCoded" />
            }
        />
    );
};

export default LikertQuestionCreationForm;
