import Telemetry from '../Telemetry/Telemetry';
import ContentTypes from '../Utils/ContentTypes';
import { HttpErrorToString } from './HttpError';

export interface HttpRequest {
    path: string;
    method?: string;
    body?: BodyInit;
    accessToken?: string;
    contentType?: undefined | string;
}

export interface Strategy {
    body: BodyInit;
    contentType: string | undefined;
}

interface HttpResponse<T> {
    ok: boolean;
    body?: T;
}

interface ErrorResponse {
    status: number;
    title: string;
    detail: string;
}

export const http = async <RESB>(config: HttpRequest): Promise<HttpResponse<RESB>> => {
    const request = new Request(config.path, {
        method: config.method || 'get',
        body: config.body,
    });
    if (config.contentType) {
        request.headers.set('Content-Type', config.contentType);
    }
    if (config.accessToken) {
        request.headers.set('authorization', `bearer ${config.accessToken}`);
    }

    // try catch handles network failures
    try {
        const response: Response = await fetch(request);

        if (response.ok) {
            // TODO: handle json parsing errors
            const body: RESB = request.method !== 'DELETE' ? await response.json() : response.status === 200;
            return { ok: response.ok, body };
        } else {
            const error: ErrorResponse = await response.json();
            Telemetry.Instance.logError(`Error requesting ${request.method} ${request.url} Body: ${error.detail}`);
            return Promise.reject(new Error(error.detail));
        }
    } catch (error) {
        Telemetry.Instance.logError(`Error requesting ${request.method} ${request.url} error: ${error.message}`);
        return Promise.reject(error);
    }
};

export async function get<RESB>(config: HttpRequest): Promise<HttpResponse<RESB>> {
    const configuration = { ...config, method: 'get' };
    return await http<RESB>(configuration);
}

export async function post<RESB>(config: HttpRequest): Promise<HttpResponse<RESB>> {
    const configuration = { ...config, method: 'post' };
    return await http<RESB>(configuration);
}

export async function put<RESB>(config: HttpRequest): Promise<HttpResponse<RESB>> {
    const configuration = { ...config, method: 'put' };
    return await http<RESB>(configuration);
}

export async function httpDelete<RESB>(config: HttpRequest): Promise<HttpResponse<RESB>> {
    const configuration = { ...config, method: 'delete' };
    return await http<RESB>(configuration);
}

export async function patch<RESB>(config: HttpRequest): Promise<HttpResponse<RESB>> {
    const configuration = { ...config, method: 'PATCH' };
    return await http<RESB>(configuration);
}

export function toFormData<T>(input: T): Strategy {
    return {
        body: toFormDataHelper(input, undefined, undefined),
        contentType: undefined,
    };
}

export function toJson<T>(input: T): Strategy {
    return {
        body: JSON.stringify(input),
        contentType: ContentTypes.ApplicationJson,
    };
}

function toFormDataHelper(input: any, form: FormData | undefined, namespace: string | undefined): FormData {
    const formData = form || new FormData();
    for (const property in input) {
        if (!input.hasOwnProperty(property)) {
            continue;
        }

        const propertyName = property.toString();
        const propertyValue = input[propertyName];

        const formKey = namespace ? `${namespace}[${propertyName}]` : propertyName;
        if (propertyValue instanceof Date) {
            formData.append(formKey, input[propertyName].toISOString());
        } else if (typeof propertyValue === 'object' && !(propertyValue instanceof File)) {
            toFormDataHelper(propertyValue, formData, formKey);
        } else if (propertyValue != null) {
            formData.append(formKey, propertyValue);
        }
    }
    return formData;
}

function ResponseToErrorMessage(status: number, text: string): string {
    let errorMessage: string;
    switch (status) {
        case 409:
            errorMessage = HttpErrorToString({
                httpCode: status,
                errorMessage: text,
            });
            break;
        default:
            errorMessage = `Status code: ${status}`;
            break;
    }
    return errorMessage;
}
