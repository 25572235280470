import { useHistory, useParams } from 'react-router-dom';
import React from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import { useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import Stack from '@material-ui/core/Stack';
import { Box, Button, Card, CardContent, CardHeader, Container, Divider } from '@material-ui/core';
import UIStrings from '../../../Utils/UIStrings';
import { RootState, useAppDispatch } from '../../../app/store';
import { updateQuestionTopic } from '../questionTopics.slice';
import QuestionTopicEditSection from './QuestionTopicEditSection';
import { QuestionTopicFormValues } from '../models/QuestionTopicFormValues';
import useStyles from '../styles';
import NavigateBackButton from '../../../Components/Custom/NavigateBackButton';
import ConfirmationBlockDialog from '../../../Components/ConfirmationDialog/ConfirmationBlockDialog';
import { findTreeNodeById, copyNodeByLevel } from '../../../Models/QuestionTopicTreeNode';
import { TestTypeTopicMap } from '../../../Models/TestTypeTopicMap';
import { DefaultOptionValue } from '../../../Models/OptionValue';

interface QuestionTopicEditProps {
    id: string;
}

function QuestionTopicEditPage(): JSX.Element {
    const classes = useStyles();

    const params = useParams<QuestionTopicEditProps>();

    const history = useHistory();
    const dispatch = useAppDispatch();
    const testTypeTopicMaps: TestTypeTopicMap[] = useSelector(
        (state: RootState) => state.configuration.testTypeTopicMaps,
    );
    const questionTopics = useSelector((state: RootState) => state.configuration.questionTopics);
    const { getAccessTokenSilently } = useAuth0();

    const topicId = Number(params?.id);
    const oldTopic = findTreeNodeById(questionTopics, topicId);
    let questionTopicValues = useSelector((state: RootState) => state.questionTopic);
    if (topicId > 0 && questionTopicValues.length === 0) {
        questionTopicValues = JSON.parse(localStorage.getItem('questionTopic') || '');
    }

    const initialValues: QuestionTopicFormValues = {
        testType: questionTopicValues[0] != null ? questionTopicValues[0].name : '',
        questionTopic: questionTopicValues[1] ?? undefined,
        questionSubtopic1: questionTopicValues[2] ?? undefined,
        questionSubtopic2: questionTopicValues[3] ?? undefined,
    };

    const submitCallback = async (
        values: QuestionTopicFormValues,
        { setSubmitting, resetForm, setFieldError }: FormikHelpers<any>,
    ) => {
        const fieldNames = getErrorFieldNames(values);
        fieldNames.forEach((fieldName) => setFieldError(fieldName, UIStrings.Required));
        if (fieldNames.length > 0) {
            return;
        }
        const topic = copyNodeByLevel(oldTopic, level);
        dispatch(updateQuestionTopic({ getAccessTokenSilently, oldTopic: topic, values }));
        resetForm();
        history.push(`/question-topics/`);
    };

    const isNullOrWhiteSpace = (value?: string): boolean => {
        return value == null || value === '' || value === ' ';
    };

    const getErrorFieldNames = (values: QuestionTopicFormValues): string[] => {
        const fieldNames: string[] = [];
        if (isNullOrWhiteSpace(values.testType)) {
            fieldNames.push('questionType');
        }

        if (
            level >= 1 &&
            (values.questionTopic == null ||
                values.questionTopic === DefaultOptionValue ||
                isNullOrWhiteSpace(values.questionTopic?.name))
        ) {
            fieldNames.push(level === 1 ? 'questionTopic.name' : 'questionTopic');
        }

        if (
            level >= 2 &&
            (values.questionSubtopic1 == null ||
                values.questionSubtopic1 === DefaultOptionValue ||
                isNullOrWhiteSpace(values.questionSubtopic1.name))
        ) {
            fieldNames.push(level === 2 ? 'questionSubtopic1.name' : 'questionSubtopic1');
        }

        if (
            level === 3 &&
            (values.questionSubtopic2 == null ||
                values.questionSubtopic2 === DefaultOptionValue ||
                isNullOrWhiteSpace(values.questionSubtopic2.name))
        ) {
            fieldNames.push('questionSubtopic2.name');
        }

        return fieldNames;
    };

    const level = questionTopicValues.length - 1;

    const title = `${UIStrings.Edit} ${UIStrings.QuestionTopic} ${topicId}`;

    return (
        <Box sx={{ padding: 3 }}>
            <Container maxWidth="sm">
                <Card>
                    <CardHeader title={title} />
                    <Divider />
                    <CardContent>
                        <Formik className={classes.root} initialValues={initialValues} onSubmit={submitCallback}>
                            {(formik) => (
                                <Form>
                                    <ConfirmationBlockDialog
                                        message={UIStrings.ChangesWouldBeLostWarning}
                                        formik={formik}
                                    />
                                    <QuestionTopicEditSection
                                        topicTree={questionTopics}
                                        initialValues={initialValues}
                                        level={level}
                                    />
                                    <Divider classes={{ root: classes.actionsDivider }} />
                                    <Stack direction="row" justifyContent="space-around">
                                        <Button variant="contained" color="primary" type="submit">
                                            {UIStrings.Submit}
                                        </Button>
                                        <NavigateBackButton />
                                    </Stack>
                                </Form>
                            )}
                        </Formik>
                    </CardContent>
                </Card>
            </Container>
        </Box>
    );
}

export default QuestionTopicEditPage;
