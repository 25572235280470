import Client from './Client';
import { mapQuestionDataFromServer, QuestionData, QuestionDataFromServer } from '../Models/QuestionData';
import QuestionTopicTreeNode from '../Models/QuestionTopicTreeNode';
import { get, patch, post, put, Strategy, toJson } from './HttpUtils';
import Page, { EmptyPage } from '../Models/Page';
import { UpsertQuestionModel } from '../Models/UpsertQuestionModel';
import ClientBase from './ClientBase';

export default class QuestionClient extends ClientBase implements Client {
    async getQuestion(id: string): Promise<QuestionData | null> {
        const accessToken = await this.getAccessTokenSilently();
        const result = await get<QuestionDataFromServer>({
            path: this.addHost(`question/${id}`),
            accessToken,
        });

        if (result.ok && result.body) {
            const questionData = mapQuestionDataFromServer(result.body);
            return questionData;
        } else {
            return null;
        }
    }

    async getQuestionTopics(): Promise<QuestionTopicTreeNode[]> {
        const accessToken = await this.getAccessTokenSilently();
        const result = await get<Page<QuestionTopicTreeNode>>({
            path: this.addHost('questionTopicTree'),
            accessToken,
        });

        if (result.ok && result.body) {
            const page = result.body;
            return page?.data || [];
        } else {
            return [];
        }
    }

    async getQuestions(offset = 0): Promise<Page<QuestionData>> {
        const accessToken = await this.getAccessTokenSilently();
        const result = await get<Page<QuestionDataFromServer>>({
            path: this.addHost(`question?offset=${offset}`),
            accessToken,
        });

        if (result.ok && result.body) {
            const page = {
                pagination: result.body.pagination,
                data: result.body.data.map(mapQuestionDataFromServer),
            };
            return page;
        } else {
            return EmptyPage;
        }
    }

    async createQuestion(
        questionData: UpsertQuestionModel,
        strategy: (x: object) => Strategy,
    ): Promise<QuestionData | null> {
        const accessToken = await this.getAccessTokenSilently();
        const str = strategy(questionData);
        const result = await post<QuestionDataFromServer | null>({
            path: this.addHost('question'),
            body: str.body,
            accessToken,
            contentType: str.contentType,
        });
        if (result.ok && result.body) {
            return mapQuestionDataFromServer(result.body);
        }
        return null;
    }

    async updateQuestion(
        questionData: UpsertQuestionModel,
        strategy: (x: object) => Strategy,
    ): Promise<QuestionData | null> {
        const str = strategy(questionData);
        const result = await put<QuestionDataFromServer | null>({
            path: this.addHost('question'),
            body: str.body,
            contentType: str.contentType,
            accessToken: await this.getAccessTokenSilently(),
        });
        if (result.ok && result.body) {
            return mapQuestionDataFromServer(result.body);
        }
        return null;
    }

    async approveQuestion(id: string): Promise<QuestionData | null> {
        const str = toJson([
            {
                op: 'replace',
                path: '/Status',
                value: 'Approved',
            },
        ]);
        const result = await patch<QuestionDataFromServer | null>({
            path: this.addHost(`question/${id}`),
            body: str.body,
            accessToken: await this.getAccessTokenSilently(),
            contentType: str.contentType,
        });
        if (result.ok && result.body) {
            return mapQuestionDataFromServer(result.body);
        } else {
            return null;
        }
    }

    async rejectQuestion(id: string, comment: string): Promise<QuestionData | null> {
        const str = toJson([
            {
                op: 'replace',
                path: '/Status',
                value: 'Rejected',
            },
            {
                op: 'replace',
                path: '/RejectionComment',
                value: comment,
            },
        ]);
        const result = await patch<QuestionDataFromServer | null>({
            path: this.addHost(`question/${id}`),
            body: str.body,
            accessToken: await this.getAccessTokenSilently(),
            contentType: str.contentType,
        });
        if (result.ok && result.body) {
            return mapQuestionDataFromServer(result.body);
        } else {
            return null;
        }
    }
}
