import React, { FC } from 'react';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { Route, RouteProps } from 'react-router-dom';
import LoadingOverlay from '../Components/LoadingOverlay';
import { isUserRoleAllowed } from './RoleProtectedComponent';
import { claimNames } from '../AppSettings';
import NotAuthorizedPage from '../Pages/NotAuthorizedPage';

interface Props extends RouteProps {
    allowedRoles?: string[];
}

const ProtectedRoute: FC<Props> = ({ allowedRoles, component, ...args }: Props) => {
    const { user } = useAuth0();

    let componentToUse = component;
    if (allowedRoles) {
        if (user && user[claimNames.role] && !isUserRoleAllowed(allowedRoles, user[claimNames.role])) {
            componentToUse = NotAuthorizedPage;
        }
    }
    return <Route {...args} component={componentToUse} />;
};

export default withAuthenticationRequired(ProtectedRoute, { onRedirecting: () => <LoadingOverlay /> });
