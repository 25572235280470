/* eslint-disable react-hooks/exhaustive-deps */
import { Button, InputLabel } from '@material-ui/core';
import Stack from '@material-ui/core/Stack';
import AddIcon from '@material-ui/icons/Add';
import { FieldArray, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { QuestionTopicFormValues } from '../models/QuestionTopicFormValues';
import QuestionTopicTreeNode, { isNodeEqualOption } from '../../../Models/QuestionTopicTreeNode';
import { DefaultOptionValue, OptionValue } from '../../../Models/OptionValue';
import mapNodeToOption from '../../../services/MapNodeToOption';
import useStyles from '../styles';
import FormikTextField from '../../../Components/FormikFormComponents/FormikTextField';
import UIStrings from '../../../Utils/UIStrings';
import FormikAutoComplete from '../../../Components/FormikFormComponents/FormikAutoComplete';
import FormikDropdown from '../../../Components/FormikFormComponents/FormikDropdown';
import { TestTypeValues } from '../../../Models/Configuration';

interface QuestionTopicAddSectionProps {
    topicTree: QuestionTopicTreeNode[];
}

const QuestionTopicAddSection = ({ topicTree }: QuestionTopicAddSectionProps) => {
    const classes = useStyles();

    const {
        values: { testType, questionTopic, questionSubtopic1, questionSubtopic2 },
        setFieldValue,
        setFieldTouched,
        dirty,
    } = useFormikContext<QuestionTopicFormValues>();

    const initialTopicNode = topicTree.find((node) => isNodeEqualOption(node, questionTopic));
    let initialSubtopic1Node;
    if (initialTopicNode) {
        initialSubtopic1Node = initialTopicNode.children?.find((node) => isNodeEqualOption(node, questionSubtopic1));
    }

    const [selectedTopicNode, setSelectedTopicNode] = useState<QuestionTopicTreeNode | undefined>(initialTopicNode);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [selectedSubtopic1Node, setSelectedSubtopic1Node] = useState<QuestionTopicTreeNode | undefined>(
        initialSubtopic1Node,
    );

    const resetFieldState = (fieldName: string) => {
        setFieldValue(fieldName, null);
        setFieldTouched(fieldName, false);
    };

    const [questionTopicOptions, setQuestionTopicOptions] = useState<OptionValue[]>([]);
    useEffect(() => {
        const filteredTopicTree = topicTree.filter((node) => node.testType === testType);
        const optionValues = mapNodeToOption(filteredTopicTree);
        setQuestionTopicOptions(optionValues);
        if (dirty) {
            resetFieldState('questionTopic');
            resetFieldState('questionSubtopic1');
            resetFieldState('questionSubtopic2');
        }
    }, [testType]);

    useEffect(() => {
        const filteredNode = topicTree.find((node) => isNodeEqualOption(node, questionTopic));
        filteredNode && setSelectedTopicNode(filteredNode);
        if (dirty) {
            resetFieldState('questionSubtopic1');
            resetFieldState('questionSubtopic2');
        }
    }, [questionTopic]);

    useEffect(() => {
        const filteredNode = selectedTopicNode?.children?.find((node) => isNodeEqualOption(node, questionSubtopic1));
        filteredNode && setSelectedSubtopic1Node(filteredNode);
    }, [questionSubtopic1]);

    const questionSubtopic1Options = mapNodeToOption(selectedTopicNode?.children ?? []);

    return (
        <div>
            <FieldArray name="topics">
                {({ insert, remove, push }) => (
                    <Stack spacing={3}>
                        <div>
                            <InputLabel htmlFor="testType">{UIStrings.TestType}</InputLabel>
                            <FormikDropdown name="testType" options={TestTypeValues} />
                        </div>
                        {questionTopic && (
                            <div>
                                <InputLabel htmlFor="questionTopic">{UIStrings.QuestionTopic}</InputLabel>
                                <FormikAutoComplete name="questionTopic" options={questionTopicOptions} />
                            </div>
                        )}
                        {questionTopic && questionSubtopic1 && (
                            <div>
                                <InputLabel htmlFor="questionSubtopic1">{UIStrings.QuestionSubTopic1}</InputLabel>
                                <FormikAutoComplete name="questionSubtopic1" options={questionSubtopic1Options} />
                            </div>
                        )}
                        {questionTopic && questionSubtopic1 && questionSubtopic2 && (
                            <div>
                                <div>
                                    <InputLabel htmlFor="questionSubtopic2">{UIStrings.QuestionSubTopic2}</InputLabel>
                                    <FormikTextField fullWidth id="questionSubtopic2" name="questionSubtopic2.name" />
                                </div>
                            </div>
                        )}
                        {(questionTopic == null || questionSubtopic1 == null || questionSubtopic2 == null) && (
                            <Button
                                color="primary"
                                className={classes.button}
                                startIcon={<AddIcon />}
                                onClick={() => {
                                    const optionValue = DefaultOptionValue;

                                    if (questionTopic == null) {
                                        setFieldValue('questionTopic', optionValue);
                                    } else if (questionSubtopic1 == null) {
                                        setFieldValue('questionSubtopic1', optionValue);
                                    } else if (questionSubtopic2 == null) {
                                        setFieldValue('questionSubtopic2', optionValue);
                                    }

                                    push(optionValue);
                                }}
                                disabled={
                                    testType === '' ||
                                    questionTopic === DefaultOptionValue ||
                                    questionSubtopic1 === DefaultOptionValue ||
                                    questionSubtopic2 === DefaultOptionValue
                                }
                            >
                                {UIStrings.AddTopic}
                            </Button>
                        )}
                    </Stack>
                )}
            </FieldArray>
        </div>
    );
};

export default QuestionTopicAddSection;
