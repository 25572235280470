import React, { FC, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { Box, Card, CardContent, CardHeader, Container, Divider } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import UIStrings from '../../../Utils/UIStrings';
import { QuestionType } from '../../../Models/Configuration';
import MultipleChoiceQuestionInfo from './Components/MultipleChoiceQuestionInfo';
import ExactQuestionInfo from './Components/ExactQuestionInfo';
import { RoleProtectedComponent } from '../../../Routing/RoleProtectedComponent';
import Role from '../../../Auth/Role';
import { RootState } from '../../../app/store';
import { loadQuestion, selectQuestionById } from '../questions.slice';
import LikertQuestionInfo from './Components/LikertQuestionInfo';

interface RouteParams {
    questionId: string;
}

const useEditButtonStyles = makeStyles((theme) => ({
    root: {
        marginRight: 8,
    },
}));

const QuestionInfoPage: FC<RouteComponentProps<RouteParams>> = ({
    match,
    history,
}: RouteComponentProps<RouteParams>) => {
    const editButtonClasses = useEditButtonStyles();
    const { questionId } = match.params;
    const dispatch = useDispatch();
    const { getAccessTokenSilently } = useAuth0();
    const question = useSelector((state: RootState) => selectQuestionById(state, questionId));

    useEffect(() => {
        if (!question) {
            dispatch(loadQuestion({ getAccessTokenSilently, questionId }));
        }
    }, []);

    const handleBackClick = async () => {
        history.goBack();
    };

    const handleEditClick = () => {
        if (questionId) {
            history.push(`/questions/edit/${questionId}`);
        }
    };

    return (
        <Box sx={{ padding: 3 }}>
            <Container maxWidth="sm">
                <Card>
                    <CardHeader
                        title={UIStrings.QuestionInfoPageTitle}
                        action={
                            question?.status !== 'Approved' && (
                                <RoleProtectedComponent
                                    allowedRoles={[Role.ContentCreator]}
                                    accessAllowedComponent={
                                        <Button
                                            classes={{ root: editButtonClasses.root }}
                                            variant="outlined"
                                            startIcon={<EditIcon />}
                                            onClick={handleEditClick}
                                        >
                                            {UIStrings.EditQuestion}
                                        </Button>
                                    }
                                />
                            )
                        }
                    />
                    <Divider />
                    <CardContent>
                        {question?.questionType === QuestionType.Choice && (
                            <MultipleChoiceQuestionInfo question={question} />
                        )}
                        {question?.questionType === QuestionType.Exact && <ExactQuestionInfo question={question} />}
                        {question?.questionType === QuestionType.Likert && <LikertQuestionInfo question={question} />}
                    </CardContent>
                    <Box sx={{ margin: 2 }}>
                        <Button fullWidth variant="outlined" onClick={handleBackClick}>
                            {UIStrings.Back}
                        </Button>
                    </Box>
                </Card>
            </Container>
        </Box>
    );
};

export default QuestionInfoPage;
