import React, { FC } from 'react';
import { Guid } from 'guid-typescript';
import { createSharedInitialQuestionCreationFormValues, QuestionCreationForm } from './QuestionCreationForm';
import FormikTextField from '../../../../Components/FormikFormComponents/FormikTextField';
import UIStrings from '../../../../Utils/UIStrings';
import { createExactQuestionValidationSchema } from './QuestionValidationSchema';
import DifficultySection from './DifficultySelector';
import { QuestionType } from '../../../../Models/Configuration';
import { QuestionFormModel } from '../../../../Models/QuestionFormModel';

const ExactQuestionCreationForm: FC = () => {
    const sharedInitialValues = createSharedInitialQuestionCreationFormValues();
    const initialValues: QuestionFormModel = {
        ...sharedInitialValues,
        questionType: QuestionType.Exact,
        answers: [
            {
                id: Guid.create().toString(),
                content: '',
                isCorrect: true,
            },
        ],
    };

    const validationSchema = createExactQuestionValidationSchema();

    return (
        <QuestionCreationForm
            initialValues={initialValues}
            validationSchema={validationSchema}
            answerSectionComponent={
                <FormikTextField fullWidth name="answers[0].content" type="TextArea" label={UIStrings.CorrectAnswer} />
            }
            additionalOptionsSectionComponent={<DifficultySection />}
        />
    );
};

export default ExactQuestionCreationForm;
