import React, { ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { authProviderSettings } from '../AppSettings';

interface Props {
    children?: ReactNode;
}

const Auth0ProviderWithHistory = ({ children }: Props) => {
    const history = useHistory();

    const onRedirectCallback = (appState: AppState) => {
        history.push(appState?.returnTo || window.location.pathname);
    };

    const providerSettings = {
        ...authProviderSettings,
        hint: 'login-password',
    };

    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Auth0Provider {...providerSettings} onRedirectCallback={onRedirectCallback}>
            {children}
        </Auth0Provider>
    );
};

export default Auth0ProviderWithHistory;
