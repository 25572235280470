export interface OptionValue {
    id: string;
    name: string;
}

export const DefaultOptionValue: OptionValue = {
    id: '',
    name: '',
};

function mapOptionValuesToValues(optionValues: OptionValue[]): OptionValue[] {
    return optionValues.map((optionValue) => ({
        id: optionValue.name,
        name: optionValue.name,
    }));
}

export default mapOptionValuesToValues;
