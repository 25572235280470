import React, { FC, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Card, CardContent, CardHeader, Container, Divider } from '@material-ui/core';
import ExactQuestionUpdateForm from './Components/ExactQuestionUpdateForm';
import MultipleChoiceQuestionUpdateForm from './Components/MultipleChoiceQuestionUpdateForm';
import { QuestionType } from '../../../Models/Configuration';
import UIStrings from '../../../Utils/UIStrings';
import { RootState } from '../../../app/store';
import { loadQuestion, selectQuestionById } from '../questions.slice';
import displayGuid from '../../../Utils/displayGuid';
import LikertQuestionUpdateForm from './Components/LikertQuestionUpdateForm';

interface RouteParams {
    questionId: string;
}

const EditQuestionPage: FC<RouteComponentProps<RouteParams>> = ({ match }: RouteComponentProps<RouteParams>) => {
    const { getAccessTokenSilently } = useAuth0();
    const id = match.params.questionId;
    const dispatch = useDispatch();
    const question = useSelector((state: RootState) => selectQuestionById(state, id));

    useEffect(() => {
        if (!question) {
            dispatch(loadQuestion({ getAccessTokenSilently, questionId: id }));
        }
    }, []);

    return (
        <Box sx={{ padding: 3 }}>
            <Container maxWidth="sm">
                <Card>
                    <CardHeader title={`${UIStrings.EditQuestionPageTitle} ${displayGuid(match.params.questionId)}`} />
                    <Divider />
                    <CardContent>
                        {question?.questionType === QuestionType.Choice && (
                            <MultipleChoiceQuestionUpdateForm question={question} />
                        )}
                        {question?.questionType === QuestionType.Exact && <ExactQuestionUpdateForm question={question} />}
                        {question?.questionType === QuestionType.Likert && (
                            <LikertQuestionUpdateForm question={question} />
                        )}
                    </CardContent>
                </Card>
            </Container>
        </Box>
    );
};

export default EditQuestionPage;
