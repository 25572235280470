import React from 'react';
import { Switch } from 'react-router-dom';

import QuestionTopicListPage from './List/QuestionTopicListPage';
import QuestionTopicEditPage from './Edit/QuestionTopicEditPage';
import QuestionTopicAddPage from './Add/QuestionTopicAddPage';
import ProtectedRoute from '../../Routing/ProtectedRoute';

function QuestionTopicsPage(): JSX.Element {
    // TODO: figure it out and refactor
    // TODO: why application is fully reloaded when changing url
    // const dispatch = useDispatch();
    // const { getAccessTokenSilently } = useAuth0();
    // useEffect(() => {
    //     dispatch(loadQuestionTopics({ getAccessTokenSilently }));
    // }, [dispatch]);
    return (
        <Switch>
            <ProtectedRoute exact path="/question-topics" component={QuestionTopicListPage} />
            <ProtectedRoute path="/question-topics/add" component={QuestionTopicAddPage} />
            <ProtectedRoute path="/question-topics/edit/:id" component={QuestionTopicEditPage} />
        </Switch>
    );
}

export default QuestionTopicsPage;
