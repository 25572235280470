import AnswerData from './AnswerData';
import QuestionTopicData from './QuestionTopicData';
import { QuestionType, QuestionDifficulty, TestType, Status } from './Configuration';

export interface QuestionDataFromServer {
    id: string;
    question: string;
    questionInfo?: string;
    testType: TestType;
    questionDifficulty: QuestionDifficulty;
    creatorId: string;
    creatorName: string;
    created: string;
    verifierId: string;
    verifierName: string;
    verificationDate?: string;
    answers: AnswerData[];
    questionType: QuestionType;
    status: Status;
    topic: QuestionTopicData;
    rejectionComment?: string;
    likertAnswerGroupId: string;
    positivelyCoded: boolean;
    rowVersion: string;
}

export interface QuestionData {
    id: string;
    question: string;
    questionInfo?: string;
    testType: TestType;
    questionDifficulty: QuestionDifficulty;
    creatorId: string;
    creatorName: string;
    creationDateValue: number;
    creationDate: string;
    verifierId: string;
    verifierName: string;
    verificationDateValue?: number;
    verificationDate?: string;
    answers: AnswerData[];
    questionType: QuestionType;
    status: Status;
    topic: QuestionTopicData;
    topicFullName: string;
    rejectionComment?: string;
    blobFileURI?: string;
    fileMimeType?: string;
    originalFilename?: string;
    likertAnswerGroupId: string;
    positivelyCoded: boolean;
    rowVersion: string;
}

export function getColorFromQuestionStatus(question: QuestionData): string {
    switch (question.status) {
        case 'Approved':
            return 'green';
        case 'Rejected':
            return 'red';
        default: {
            return 'bodySubtext';
        }
    }
}

export function mapQuestionDataFromServer(data: QuestionDataFromServer): QuestionData {
    return {
        ...data,
        creationDateValue: new Date(data.created).valueOf(),
        creationDate: new Date(data.created).toLocaleString(),
        verificationDateValue: data.verificationDate ? new Date(data.verificationDate).valueOf() : undefined,
        verificationDate: data.verificationDate ? new Date(data.verificationDate).toLocaleString() : undefined,
        topicFullName: getFullTopicName(data.topic),
    };
}

export function getFullTopicName(rootTopic: QuestionTopicData | undefined): string {
    const result: string[] = [];
    let currTopic: QuestionTopicData | undefined = rootTopic;
    while (currTopic) {
        result.unshift(currTopic.questionTopicName);
        currTopic = currTopic.parent;
    }

    return result.join(' / ');
}
