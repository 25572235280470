import React from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import NavItem, { NavItemProps } from './NavBar/NavItem';
import { claimNames } from '../AppSettings';
import { isUserRoleAllowed } from '../Routing/RoleProtectedComponent';

interface ProtectedNavItemProps extends NavItemProps {
    allowedRoles: string[];
}

function ProtectedNavItem({ href, title, allowedRoles }: ProtectedNavItemProps): JSX.Element {
    const { user } = useAuth0();

    const allowed = user && user[claimNames.role] && isUserRoleAllowed(allowedRoles, user[claimNames.role]);

    return <>{allowed && <NavItem href={href} title={title} />}</>;
}

export default ProtectedNavItem;
