import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    button: {
        height: 30,
        fontSize: 12,
    },
    paper: {
        width: '80%',
        maxHeight: 435,
    },
    actionsDivider: {
        marginTop: 20,
        marginBottom: 20,
    },
}));

export default useStyles;
