import React, { FC } from 'react';

import AnswerData from '../../../../Models/AnswerData';
import UIStrings from '../../../../Utils/UIStrings';
import LabeledText from '../../../../Components/Custom/LabeledText';

interface Props {
    answers: AnswerData[];
}
const ExactAnswerInfo: FC<Props> = ({ answers }: Props) => {
    return <LabeledText label={UIStrings.CorrectAnswer} text={answers[0].content} />;
};

export default ExactAnswerInfo;
