import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Box, Card, CardContent, CardHeader, Container, Divider } from '@material-ui/core';
import ExactQuestionCreationForm from './Components/ExactQuestionCreationForm';
import MultipleChoiceQuestionCreationForm from './Components/MultipleChoiceQuestionCreationForm';
import { QuestionType } from '../../../Models/Configuration';
import QueryParameterStrings from '../../../Utils/QueryParameterStrings';
import UIStrings from '../../../Utils/UIStrings';
import LikertQuestionCreationForm from './Components/LikertQuestionCreationForm';

const CreateQuestionPage: FC<RouteComponentProps> = ({ location }: RouteComponentProps) => {
    const searchParams = new URLSearchParams(location.search);
    const questionType = (searchParams.get(QueryParameterStrings.QuestionType) as QuestionType) || '';

    return (
        <Box sx={{ padding: 3 }}>
            <Container maxWidth="sm">
                <Card>
                    <CardHeader title={UIStrings.CreateQuestionPageTitle} />
                    <Divider />
                    <CardContent>
                        {questionType === QuestionType.Choice && <MultipleChoiceQuestionCreationForm />}
                        {questionType === QuestionType.Exact && <ExactQuestionCreationForm />}
                        {questionType === QuestionType.Likert && <LikertQuestionCreationForm />}
                    </CardContent>
                </Card>
            </Container>
        </Box>
    );
};

export default CreateQuestionPage;
