export interface Pagination {
    offset: number;
    limit: number;
    totalCount: number;
}

export default interface Page<T> {
    pagination: Pagination;
    data: T[];
}

export const EmptyPage: Page<any> = {
    pagination: {
        offset: 0,
        limit: 0,
        totalCount: 0,
    },
    data: [],
};
