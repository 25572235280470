/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Box, FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core';
import Stack from '@material-ui/core/Stack';
import { useFormikContext } from 'formik';
import QuestionTopicTreeNode, { isNodeEqualOption } from '../../../Models/QuestionTopicTreeNode';
import FormikTextField from '../../../Components/FormikFormComponents/FormikTextField';
import { QuestionTopicFormValues } from '../models/QuestionTopicFormValues';
import { OptionValue } from '../../../Models/OptionValue';
import mapNodeToOption from '../../../services/MapNodeToOption';
import UIStrings from '../../../Utils/UIStrings';
import FormikDropdown from '../../../Components/FormikFormComponents/FormikDropdown';
import { TestTypeValues } from '../../../Models/Configuration';

interface QuestionTopicEditSectionProps {
    topicTree: QuestionTopicTreeNode[];
    initialValues: QuestionTopicFormValues;
    level: number;
}

const QuestionTopicEditSection = ({ topicTree, initialValues, level }: QuestionTopicEditSectionProps) => {
    const {
        values: { testType, questionTopic, questionSubtopic1 },
        setFieldValue,
        setFieldTouched,
        dirty,
        getFieldMeta,
    } = useFormikContext<QuestionTopicFormValues>();

    const initialTopicNode = topicTree.find((node) => isNodeEqualOption(node, questionTopic));
    let initialSubtopic1Node;
    if (initialTopicNode) {
        initialSubtopic1Node = initialTopicNode.children?.find((node) => isNodeEqualOption(node, questionSubtopic1));
    }

    const [selectedTopicNode, setSelectedTopicNode] = useState<QuestionTopicTreeNode | undefined>(initialTopicNode);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [selectedSubtopic1Node, setSelectedSubtopic1Node] = useState<QuestionTopicTreeNode | undefined>(
        initialSubtopic1Node,
    );

    const resetFieldState = (fieldName: string) => {
        setFieldValue(fieldName, null);
        setFieldTouched(fieldName, false);
    };

    useEffect(() => {
        setFieldValue('questionType', initialValues.testType);
        setFieldValue('questionTopic', initialValues.questionTopic);
        setFieldValue('questionSubtopic1', initialValues.questionSubtopic1);
        setFieldValue('questionSubtopic2', initialValues.questionSubtopic2);
    }, [initialValues]);

    const [questionTopicOptions, setQuestionTopicOptions] = useState<OptionValue[]>([]);
    useEffect(() => {
        const filteredTopicTree = topicTree.filter((node) => node.testType === testType);
        const optionValues = mapNodeToOption(filteredTopicTree);
        setQuestionTopicOptions(optionValues);
        if (dirty && level > 1) {
            resetFieldState('questionTopic');
        }
        if (dirty && level > 2) {
            resetFieldState('questionSubtopic1');
        }
    }, [testType, topicTree]);

    useEffect(() => {
        const filteredNode = topicTree.find((node) => isNodeEqualOption(node, questionTopic));
        filteredNode && setSelectedTopicNode(filteredNode);
        if (dirty && level > 2) {
            resetFieldState('questionSubtopic1');
        }
    }, [questionTopic, topicTree]);

    useEffect(() => {
        const filteredNode = selectedTopicNode?.children?.find((node) => isNodeEqualOption(node, questionSubtopic1));
        filteredNode && setSelectedSubtopic1Node(filteredNode);
    }, [questionSubtopic1, selectedTopicNode]);

    const questionSubtopic1Options = mapNodeToOption(selectedTopicNode?.children ?? []);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: any, fieldName: string) => {
        const node: QuestionTopicTreeNode | undefined = {
            id: newValue.props.id,
            name: newValue.props.value,
            testType,
            children: [],
        };
        setFieldValue(fieldName, node);
        switch (fieldName) {
            case 'questionTopic':
                setSelectedTopicNode(node);
                break;
            case 'questionSubtopic1':
                setSelectedSubtopic1Node(node);
                break;
        }
    };

    return (
        <>
            <Stack spacing={4}>
                <div>
                    <InputLabel htmlFor="testType">{UIStrings.TestType}</InputLabel>
                    <FormikDropdown name="testType" options={TestTypeValues} defaultValue={testType} />
                </div>
                <Stack spacing={1}>
                    {level >= 1 && <InputLabel htmlFor="questionTopic">{UIStrings.QuestionTopic}</InputLabel>}
                    {level === 1 && <FormikTextField id="questionTopic" name="questionTopic.name" />}
                    {level > 1 && (
                        <FormControl fullWidth error={getFieldMeta('questionTopic').error != null} variant="outlined">
                            <Select
                                error={getFieldMeta('questionTopic').error != null}
                                fullWidth
                                variant="outlined"
                                id="questionTopic"
                                defaultValue={questionTopic?.name}
                                onChange={(event, newValue) => handleChange(event, newValue, 'questionTopic')}
                            >
                                {questionTopicOptions.map((topic) => (
                                    <MenuItem value={topic.name} id={`${topic.id}`} key={topic.id}>
                                        {topic.name}
                                    </MenuItem>
                                ))}
                            </Select>
                            {getFieldMeta('questionTopic').error != null && (
                                <FormHelperText>{getFieldMeta('questionTopic').error}</FormHelperText>
                            )}
                        </FormControl>
                    )}
                </Stack>

                {level >= 2 && (
                    <Stack spacing={1}>
                        {level >= 2 && (
                            <InputLabel htmlFor="questionSubtopic1">{UIStrings.QuestionSubTopic1}</InputLabel>
                        )}
                        {level === 2 && <FormikTextField id="questionSubtopic1" name="questionSubtopic1.name" />}
                        {level > 2 && (
                            <FormControl
                                fullWidth
                                error={getFieldMeta('questionSubtopic1').error != null}
                                variant="outlined"
                            >
                                <Select
                                    fullWidth
                                    variant="outlined"
                                    defaultValue={questionSubtopic1?.name}
                                    onChange={(event, newValue) => handleChange(event, newValue, 'questionSubtopic1')}
                                >
                                    {questionSubtopic1Options.map((topic) => (
                                        <MenuItem value={topic.name} id={`${topic.id}`} key={topic.id}>
                                            {topic.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {getFieldMeta('questionSubtopic1').error != null && (
                                    <FormHelperText>{getFieldMeta('questionSubtopic1').error}</FormHelperText>
                                )}
                            </FormControl>
                        )}
                    </Stack>
                )}

                {level === 3 && (
                    <Stack spacing={1}>
                        <InputLabel htmlFor="questionSubtopic2">{UIStrings.QuestionSubTopic2}</InputLabel>
                        <FormikTextField id="questionSubtopic2" name="questionSubtopic2.name" />
                    </Stack>
                )}
            </Stack>
            <Box sx={{ marginBottom: 4 }} />
        </>
    );
};

export default QuestionTopicEditSection;
