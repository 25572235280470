import { LikertAnswerData } from './LikertAnswerData';
import { OptionValue } from './OptionValue';

export interface LikertAnswerGroupData {
    id: string;
    groupName: string;
    answers: LikertAnswerData[];
}

export function mapLikertAnswerGroupsToOptionValues(groups: LikertAnswerGroupData[]): OptionValue[] {
    return groups?.map((group) => {
        return {
            id: group.id,
            name: group.groupName,
        };
    });
}
