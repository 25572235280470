import React, { FC } from 'react';
import { Guid } from 'guid-typescript';
import { UpsertQuestionModel } from '../../../../Models/UpsertQuestionModel';
import MultipleChoiceAnswerContainer from './MultipleChoiceAnswerContainer';
import { createSharedInitialQuestionCreationFormValues, QuestionCreationForm } from './QuestionCreationForm';
import { createMultipleChoiceQuestionValidationSchema } from './QuestionValidationSchema';
import DifficultySection from './DifficultySelector';

const MultipleChoiceQuestionCreationForm: FC = () => {
    const sharedInitialValues = createSharedInitialQuestionCreationFormValues();
    const initialValues: UpsertQuestionModel = {
        ...sharedInitialValues,
        answers: [
            {
                id: Guid.create().toString(),
                content: '',
                isCorrect: false,
            },
            {
                id: Guid.create().toString(),
                content: '',
                isCorrect: false,
            },
            {
                id: Guid.create().toString(),
                content: '',
                isCorrect: false,
            },
            {
                id: Guid.create().toString(),
                content: '',
                isCorrect: false,
            },
        ],
    };

    const validationSchema = createMultipleChoiceQuestionValidationSchema();

    return (
        <QuestionCreationForm
            initialValues={initialValues}
            validationSchema={validationSchema}
            answerSectionComponent={<MultipleChoiceAnswerContainer />}
            additionalOptionsSectionComponent={<DifficultySection />}
        />
    );
};

export default MultipleChoiceQuestionCreationForm;
