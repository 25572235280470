import ITelemetry from './ITelemetry';

export default class ConsoleLogger implements ITelemetry {
    public logError(message: string): void {
        // eslint-disable-next-line no-console
        console.log(`Error: ${message}`);
    }

    public logWarning(message: string): void {
        // eslint-disable-next-line no-console
        console.log(`Warning: ${message}`);
    }

    public logEvent(message: string): void {
        // eslint-disable-next-line no-console
        console.log(`Event: ${message}`);
    }
}
