import { TestType, QuestionDifficulty, Status, QuestionType } from './Configuration';
import AnswerData from './AnswerData';
import { QuestionFormModel } from './QuestionFormModel';

export interface UpsertQuestionModel {
    id: string;
    question: string;
    testType: TestType;
    questionDifficulty: QuestionDifficulty;
    questionTopic: string;
    blobFileURI?: string;
    originalFilename?: string;
    creatorId: string;
    file?: File;
    verifierId: string;
    status: Status;
    answers: AnswerData[];
    rowVersion: string;
    questionType: QuestionType;
}

export interface ExactQuestionUpsertQuestionModel extends UpsertQuestionModel {
    correctAnswer: string;
}

export interface LikertQuestionUpsertQuestionModel extends UpsertQuestionModel {
    likertAnswerGroupId: string;
    positivelyCoded: boolean;
}

export function mapQuestionFormModelToUpsertQuestionModel(input: QuestionFormModel): UpsertQuestionModel {
    const { questionTopic, questionSubtopic1, questionSubtopic2, ...commonProperties } = input;

    const result: UpsertQuestionModel = {
        ...commonProperties,
        questionTopic: findTopicLeaf(input),
    };

    return result;
}

/**
 * @param input question form model
 * @returns The deepest non empty topic or subtopic.
 */
function findTopicLeaf(input: QuestionFormModel): string {
    if (input.questionSubtopic2) {
        return input.questionSubtopic2;
    }

    if (input.questionSubtopic1) {
        return input.questionSubtopic1;
    }
    return input.questionTopic;
}
