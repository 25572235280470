import React from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import { DataGrid, GridCellParams, GridColDef, GridValueGetterParams } from '@material-ui/data-grid';
import { Button, Card, CardContent } from '@material-ui/core';
import UIStrings from '../../../Utils/UIStrings';
import { getColorFromQuestionStatus, getFullTopicName, QuestionData } from '../../../Models/QuestionData';
import { RootState } from '../../../app/store';
import { loadQuestions } from '../questions.slice';
import { ProcessRunning } from '../../../Models/processable';
import displayGuid from '../../../Utils/displayGuid';

interface QuestionListProps {
    searchValue: string;
}

export interface QuestionListState {
    columns: GridColDef[];
    items: QuestionData[];
    itemTotalCount: number | undefined;
}

const QuestionListResults = ({ searchValue }: QuestionListProps) => {
    const page = useSelector((state: RootState) => state.questions);
    const dispatch = useDispatch();
    const { getAccessTokenSilently } = useAuth0();

    const fetchQuestions = () => {
        dispatch(loadQuestions({ getAccessTokenSilently, offset: page.data.length }));
    };

    const getQuestionData = (params: GridValueGetterParams) => {
        return (params.row as unknown) as QuestionData;
    };

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: UIStrings.IdColumnTitle,
            width: 100,
            valueGetter: (params: GridValueGetterParams) => {
                const item = getQuestionData(params);

                return displayGuid(item.id);
            },
        },
        {
            field: 'question',
            headerName: UIStrings.Question,
            width: 200,
        },
        {
            field: 'questionInfo',
            headerName: UIStrings.QuestionInfo,
            width: 200,
        },
        {
            field: 'status',
            headerName: UIStrings.Status,
            width: 100,
            renderCell: (params: GridCellParams) => {
                const item = getQuestionData(params);
                return (
                    <span
                        css={css`
                            color: ${getColorFromQuestionStatus(item)};
                        `}
                    >
                        {item.status}
                    </span>
                );
            },
        },
        {
            field: 'topic',
            headerName: UIStrings.Topic,
            width: 350,
            valueGetter: (params: GridValueGetterParams) => {
                const item: QuestionData = (params.row as unknown) as QuestionData;

                return getFullTopicName(item.topic);
            },
        },
        {
            field: 'questionDifficulty',
            headerName: UIStrings.QuestionDifficulty,
            width: 180,
        },

        {
            field: 'answerType',
            headerName: UIStrings.QuestionType,
            width: 150,
        },
        {
            field: 'creatorName',
            headerName: UIStrings.CreatorHistoryColumnTitle,
            width: 150,
        },
        {
            field: 'creationDateValue',
            headerName: UIStrings.CreationDate,
            width: 160,

            sortable: true,

            renderCell: (params: GridCellParams) => {
                const item = getQuestionData(params);
                return <span>{item.creationDate}</span>;
            },
        },
        {
            field: 'verifierName',
            headerName: UIStrings.Verifier,
            width: 150,
        },
        {
            field: 'verificationDateValue',
            headerName: UIStrings.VerificationDate,
            width: 160,

            renderCell: (params: GridCellParams) => {
                const item = getQuestionData(params);
                return <span>{item.verificationDate}</span>;
            },
        },
    ];

    const history = useHistory();

    const filteredItems = filterItems(searchValue, page.data);
    const isLoadMoreButtonDisabled = page.pagination.totalCount
        ? page.data.length === page.pagination.totalCount
        : true;

    const onItemInvoked = (param: GridCellParams, event: React.MouseEvent): void => {
        const item = getQuestionData(param);
        history.push(`/questions/${item.id}`);
    };

    return (
        <Card>
            <CardContent>
                <DataGrid
                    rows={filteredItems}
                    columns={columns}
                    onCellClick={onItemInvoked}
                    autoHeight
                    hideFooterPagination
                    hideFooter
                    loading={page.processState === ProcessRunning}
                />
            </CardContent>
            <div
                css={css`
                    margin: 10px 0px 20px 0px;
                    display: flex;
                    justify-content: center;
                `}
            >
                <Button color="primary" onClick={fetchQuestions} disabled={isLoadMoreButtonDisabled}>
                    {UIStrings.LoadMore}
                </Button>
            </div>
        </Card>
    );
};

const filterItems = (filterValue: string, items: QuestionData[]): QuestionData[] => {
    const isMatched = (a?: string, b?: string): boolean => {
        return b == null || (a != null && a.toLowerCase().indexOf(b.toLowerCase()) > -1);
    };

    const filteredQuestions = filterValue
        ? items?.filter(
              (q: QuestionData) =>
                  isMatched(q.question, filterValue) ||
                  isMatched(q.questionInfo, filterValue) ||
                  isMatched(q.questionType, filterValue) ||
                  isMatched(q.creatorName, filterValue) ||
                  isMatched(q.verifierName, filterValue) ||
                  isMatched(q.status, filterValue) ||
                  isMatched(q.topicFullName, filterValue) ||
                  isMatched(q.questionDifficulty, filterValue),
          )
        : items;
    return filteredQuestions;
};
export default QuestionListResults;
