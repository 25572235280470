import * as yup from 'yup';
import AnswerData from '../../../../Models/AnswerData';
import UIStrings from '../../../../Utils/UIStrings';

export function createSharedValidationSchema() {
    return {
        questionType: yup.string().required(UIStrings.Required),
        questionTopic: yup.string().required(UIStrings.Required),
        questionSubtopic1: yup.string().required(UIStrings.Required),
        question: yup.string().required(UIStrings.Required),
        imageURL: yup.mixed(),
        verifierId: yup.string().required(UIStrings.Required),
    };
}

export function createMultipleChoiceQuestionValidationSchema() {
    const sharedValidationScheme = createSharedValidationSchema();
    const answersMaxLength = 500;
    const validationSchema = yup.object({
        ...sharedValidationScheme,
        questionSubtopic2: yup.string().required(UIStrings.Required),
        questionDifficulty: yup.string().required(UIStrings.Required),
        answers: yup
            .array()
            .of(
                yup.object().shape({
                    content: yup.string().required(UIStrings.Required),
                }),
            )
            .required('Must have answers')
            .min(4, UIStrings.MinimumFourAnswersValidationMessage)
            .max(5, UIStrings.MaximumFiveAnswersValidationMessage)
            .test(
                'AtLeastOneAnswerMustBeCorrect',
                UIStrings.AtLeastOneAnswerMustBeCorrectValidationMessage,
                (answers: AnswerData[]) => answers.some((answer) => answer.isCorrect),
            )
            .test(
                'AtLeastOneAnswerMustBeIncorrect',
                UIStrings.AtLeastOneAnswerMustBeIncorrectValidationMessage,
                (answers: AnswerData[]) => answers.some((answer) => !answer.isCorrect),
            )
            .test(
                `Length of each answers must be no more than ${answersMaxLength} symbols`,
                `${UIStrings.AnswerExceedsMaximumLength} of ${answersMaxLength}`,
                (answers: AnswerData[]) => answers.every((item) => item.content.length <= answersMaxLength),
            ),
    });

    return validationSchema;
}

export function createExactQuestionValidationSchema() {
    const sharedValidationScheme = createSharedValidationSchema();
    const validationSchema = yup.object({
        ...sharedValidationScheme,
        questionSubtopic2: yup.string().required(UIStrings.Required),
        questionDifficulty: yup.string().required(UIStrings.Required),
        answers: yup
            .array()
            .of(
                yup.object().shape({
                    content: yup.number().required(UIStrings.Required),
                }),
            )
            .required('Must have answers')
            .min(1, UIStrings.OneExactAnswer)
            .max(1, UIStrings.OneExactAnswer),
    });

    return validationSchema;
}

export function createLikertQuestionValidationSchema() {
    const sharedValidationScheme = createSharedValidationSchema();
    const validationSchema = yup.object({
        ...sharedValidationScheme,
        likertAnswerGroupId: yup.string().required(UIStrings.Required),
    });

    return validationSchema;
}
