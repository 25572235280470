import { FormControlLabel, Radio, Stack, Typography, useTheme } from '@material-ui/core';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import UIStrings from '../../../../Utils/UIStrings';

interface Props {
    likertGroupId: string;
}

const LikertAnswerInfo: FC<Props> = ({ likertGroupId }: Props) => {
    const theme = useTheme();
    const configuration = useSelector((state: RootState) => state.configuration);

    const selectedGroup = configuration.likertAnswerGroups?.filter((group) => group.id === likertGroupId)[0];

    return (
        <Stack spacing={3}>
            {selectedGroup ? (
                <Stack direction="row" spacing={1}>
                    {selectedGroup.answers.map((answer) => (
                        <FormControlLabel
                            key={answer.id}
                            control={<Radio />}
                            label={answer.content}
                            labelPlacement="top"
                            disabled
                        />
                    ))}
                </Stack>
            ) : (
                <Typography variant="body1" color={theme.palette.error.main}>
                    {UIStrings.NoLikertAnswerGroupAvailable}
                </Typography>
            )}
        </Stack>
    );
};

export default LikertAnswerInfo;
