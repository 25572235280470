import { createAsyncThunk } from '@reduxjs/toolkit';
import QuestionTopicTreeNode, { QuestionTopicDomain } from '../../Models/QuestionTopicTreeNode';
import QuestionTopicClient from './services/QuestionTopicClient';
import { QuestionTopicFormValues } from './models/QuestionTopicFormValues';
import { GetAccessTokenSilently } from '../../Clients/ClientBase';
import { AddEventName, DeleteEventName, UpdateEventName } from '../../Models/slice.consts';
import mapQuestionTopicFormValuesToQuestionTopicTreeNode from './services/mapQuestionTopicFormValuesToQuestionTopicTreeNode';
import { TestType } from '../../Models/Configuration';

export interface QuestionTopicsResult {
    topics: (QuestionTopicTreeNode | null)[];
    testType: TestType;
}

export const addQuestionTopic = createAsyncThunk<
    QuestionTopicsResult,
    { getAccessTokenSilently: GetAccessTokenSilently; values: QuestionTopicFormValues }
>(`${QuestionTopicDomain}/${AddEventName}`, async (thunkAPI) => {
    const { getAccessTokenSilently, values } = thunkAPI;
    const client = new QuestionTopicClient(getAccessTokenSilently);

    const topic = mapQuestionTopicFormValuesToQuestionTopicTreeNode(values);
    const newTopic = await client.addQuestionTopicTree(values.testType, topic);
    const result: QuestionTopicsResult = {
        topics: [newTopic],
        testType: values.testType as TestType,
    };

    return result;
});

export const updateQuestionTopic = createAsyncThunk<
    QuestionTopicsResult,
    {
        getAccessTokenSilently: GetAccessTokenSilently;
        oldTopic: QuestionTopicTreeNode | null;
        values: QuestionTopicFormValues;
    }
>(`${QuestionTopicDomain}/${UpdateEventName}`, async (thunkAPI) => {
    const { getAccessTokenSilently, oldTopic, values } = thunkAPI;
    const client = new QuestionTopicClient(getAccessTokenSilently);

    const topic = mapQuestionTopicFormValuesToQuestionTopicTreeNode(values);
    const newTopic = await client.updateQuestionTopicTree(values.testType, topic);
    const result: QuestionTopicsResult = {
        topics: [oldTopic, newTopic],
        testType: values.testType as TestType,
    };
    return result;
});

export const deleteQuestionTopic = createAsyncThunk<
    number | null,
    { getAccessTokenSilently: GetAccessTokenSilently; topicId: number }
>(`${QuestionTopicDomain}/${DeleteEventName}`, async (thunkAPI) => {
    const { getAccessTokenSilently, topicId } = thunkAPI;
    const client = new QuestionTopicClient(getAccessTokenSilently);
    return (await client.deleteQuestionTopicTree(topicId)) ? topicId : null;
});
