import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Formik, Form } from 'formik';
import Stack from '@material-ui/core/Stack';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { Button } from '@material-ui/core';
import UIStrings from '../../../../Utils/UIStrings';
import FormikTextField from '../../../../Components/FormikFormComponents/FormikTextField';
import { approveQuestion, rejectQuestion } from '../../questions.slice';

export interface QuestionManagementPanelValues {
    rejectionComment: string;
}

function createRejectionCommentValidationSchema() {
    return yup.object({
        rejectionComment: yup.string().required(UIStrings.Required),
    });
}

interface QuestionManagementPanelProps {
    questionId: string;
}

function QuestionManagementPanel(props: QuestionManagementPanelProps) {
    const dispatch = useDispatch();
    const { getAccessTokenSilently } = useAuth0();

    const approveHandler = async () => {
        dispatch(approveQuestion({ getAccessTokenSilently, questionId: props.questionId }));
    };
    const submitHandler = async (values: QuestionManagementPanelValues) => {
        dispatch(
            rejectQuestion({
                getAccessTokenSilently,
                questionId: props.questionId,
                rejectionComment: values.rejectionComment,
            }),
        );
    };

    return (
        <Formik
            initialValues={{ rejectionComment: '' }}
            onSubmit={submitHandler}
            validationSchema={createRejectionCommentValidationSchema()}
        >
            <Form>
                <Stack spacing={2}>
                    <FormikTextField
                        name="rejectionComment"
                        type="TextArea"
                        multiline
                        rows={5}
                        label={UIStrings.RejectionComment}
                    />
                    <Stack direction="row" justifyContent="space-around" spacing={2}>
                        <Button fullWidth color="primary" variant="contained" onClick={approveHandler}>
                            {UIStrings.ApproveQuestion}
                        </Button>
                        <Button fullWidth color="secondary" variant="contained" type="submit">
                            {UIStrings.RejectQuestion}
                        </Button>
                    </Stack>
                </Stack>
            </Form>
        </Formik>
    );
}

export default QuestionManagementPanel;
