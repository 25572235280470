import ConsoleLogger from './ConsoleLogger';
import ITelemetry from './ITelemetry';

export default class Telemetry implements ITelemetry {
    private telemetryClients: ITelemetry[];

    private static telemetryInstance: Telemetry;

    /**
     * Returns an instance of Telemetry
     */
    public static get Instance(): Telemetry {
        if (!this.telemetryInstance) {
            this.telemetryInstance = new Telemetry();
        }
        return this.telemetryInstance;
    }

    /**
     * Creates an instance of Telemetry
     */
    private constructor() {
        this.telemetryClients = new Array<ITelemetry>();
        this.telemetryClients.push(new ConsoleLogger());
    }

    /**
     * Logs an error
     */
    public logError = (message: string) => {
        this.telemetryClients.forEach((client) => {
            client.logError(message);
        });
    };

    /**
     * Logs a warning
     */
    public logWarning = (message: string) => {
        this.telemetryClients.forEach((client) => {
            client.logWarning(message);
        });
    };

    /**
     * Logs an event
     */
    public logEvent = (message: string) => {
        this.telemetryClients.forEach((client) => {
            client.logWarning(message);
        });
    };
}
