import { Button, Theme } from '@material-ui/core';
import { Link } from 'react-router-dom';
import React from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        marginRight: 50,
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        },
    },
    label: {
        textTransform: 'none',
        fontSize: theme.typography.h4.fontSize,
    },
}));

const Logo = () => {
    const classes = useStyles();
    return (
        <Button
            classes={{ root: classes.root, text: classes.label }}
            variant="text"
            disableRipple
            disableFocusRipple
            disableElevation
            color="inherit"
            component={Link}
            to="/"
        >
            TalentMesh
        </Button>
    );
};

export default Logo;
