import { StringToHttpError } from '../Clients/HttpError';
import { ProcessConflict, ProcessError, ProcessState } from '../Models/processable';

export function getErrorMessage(error: any): string {
    const httpError = StringToHttpError(error.message);
    return httpError != null && httpError.errorMessage != null ? httpError.errorMessage : error?.message;
}
export function getProcessingState(error: any): ProcessState {
    const httpError = StringToHttpError(error.message);
    return httpError?.httpCode === 409 ? ProcessConflict : ProcessError;
}
