import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import QuestionTopicTreeNode from '../../../../Models/QuestionTopicTreeNode';
import UIStrings from '../../../../Utils/UIStrings';
import { OptionValue } from '../../../../Models/OptionValue';
import mapNodeToOption from '../../../../services/MapNodeToOption';
import FormikDropdown from '../../../../Components/FormikFormComponents/FormikDropdown';
import { RootState } from '../../../../app/store';
import { mapUserProfilesToOptionValues } from '../../../../Models/UserProfile';
import { TestTypeValues } from '../../../../Models/Configuration';
import { QuestionFormModel } from '../../../../Models/QuestionFormModel';

interface Props {
    topicTree: QuestionTopicTreeNode[];
}

const QuestionCatergorySection = ({ topicTree }: Props) => {
    const {
        values: { testType, questionTopic, questionSubtopic1, questionSubtopic2, verifierId },
        setFieldValue,
        setFieldTouched,
        dirty,
    } = useFormikContext<QuestionFormModel>();

    const configuration = useSelector((state: RootState) => state.configuration);

    const testTypeDropdownOptions: OptionValue[] = TestTypeValues;
    const contentVerifierOptions: OptionValue[] = mapUserProfilesToOptionValues(configuration.contentVerifiers);

    const matchNode = (node: QuestionTopicTreeNode, value: string) => {
        return node.id.toString() === value;
    };

    const initialTopicNode = topicTree.find((node) => matchNode(node, questionTopic));
    let initialSubtopic1Node;
    if (initialTopicNode) {
        initialSubtopic1Node = initialTopicNode.children?.find((node) => matchNode(node, questionSubtopic1));
    }

    const [selectedTopicNode, setSelectedTopicNode] = useState<QuestionTopicTreeNode | undefined>(initialTopicNode);
    const [selectedSubtopic1Node, setSelectedSubtopic1Node] = useState<QuestionTopicTreeNode | undefined>(
        initialSubtopic1Node,
    );

    const resetFieldState = (fieldName: string) => {
        setFieldValue(fieldName, '');
        setFieldTouched(fieldName, false);
    };

    useEffect(() => {
        if (dirty) {
            resetFieldState('questionTopic');
            resetFieldState('questionSubtopic1');
            resetFieldState('questionSubtopic2');

            setSelectedTopicNode(undefined);
            setSelectedSubtopic1Node(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [testType]);

    useEffect(() => {
        const filteredNode = topicTree.find((node) => matchNode(node, questionTopic));
        filteredNode && setSelectedTopicNode(filteredNode);
        if (dirty) {
            resetFieldState('questionSubtopic1');
            resetFieldState('questionSubtopic2');
            setSelectedSubtopic1Node(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [questionTopic]);

    useEffect(() => {
        const filteredNode = selectedTopicNode?.children?.find((node) => matchNode(node, questionSubtopic1));
        filteredNode && setSelectedSubtopic1Node(filteredNode);
        if (dirty) {
            resetFieldState('questionSubtopic2');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [questionSubtopic1]);

    const filteredTopicTree = topicTree.filter((node) => node.testType === testType);
    const questionTopicDropdownOptions = mapNodeToOption(filteredTopicTree);

    const questionSubtopic1DropdownOptions = mapNodeToOption(selectedTopicNode?.children ?? []);

    const questionSubtopic2DropdownOptions = mapNodeToOption(selectedSubtopic1Node?.children ?? []);

    return (
        <>
            <FormikDropdown
                name="testType"
                placeholder={UIStrings.SelectTestTypePlaceholder}
                label={UIStrings.TestType}
                options={testTypeDropdownOptions}
            />

            {questionTopicDropdownOptions.length > 0 && (
                <FormikDropdown
                    name="questionTopic"
                    placeholder={UIStrings.SelectQuestionTopicPlaceholder}
                    label={UIStrings.QuestionTopic}
                    options={questionTopicDropdownOptions}
                    disabled={testType === undefined}
                />
            )}

            {questionSubtopic1DropdownOptions.length > 0 && (
                <FormikDropdown
                    name="questionSubtopic1"
                    placeholder={UIStrings.SelectQuestionSubTopic1Placeholder}
                    label={UIStrings.QuestionSubTopic1}
                    options={questionSubtopic1DropdownOptions}
                    disabled={questionTopic === ''}
                />
            )}

            {questionSubtopic2DropdownOptions.length > 0 && (
                <FormikDropdown
                    name="questionSubtopic2"
                    placeholder={UIStrings.SelectQuestionSubTopic2Placeholder}
                    label={UIStrings.QuestionSubTopic2}
                    options={questionSubtopic2DropdownOptions}
                    disabled={questionSubtopic1 === ''}
                />
            )}

            <FormikDropdown
                name="verifierId"
                placeholder={UIStrings.SelectContentVerifierPlaceholder}
                label={UIStrings.ContentVerifier}
                options={contentVerifierOptions}
            />
        </>
    );
};

export default QuestionCatergorySection;
