import { useFormikContext } from 'formik';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Radio, Stack, FormControlLabel, Typography, useTheme } from '@material-ui/core';
import UIStrings from '../../../../Utils/UIStrings';
import { LikertQuestionUpsertQuestionModel } from '../../../../Models/UpsertQuestionModel';
import FormikDropdown from '../../../../Components/FormikFormComponents/FormikDropdown';
import { RootState } from '../../../../app/store';
import { OptionValue } from '../../../../Models/OptionValue';
import { mapLikertAnswerGroupsToOptionValues } from '../../../../Models/LikertAnswerGroupData';

const LikertAnswerContainer: FC = () => {
    const theme = useTheme();
    const configuration = useSelector((state: RootState) => state.configuration);
    const likertAnswerGroupDropdownOptions: OptionValue[] = mapLikertAnswerGroupsToOptionValues(
        configuration.likertAnswerGroups,
    );

    const {
        values: { likertAnswerGroupId },
    } = useFormikContext<LikertQuestionUpsertQuestionModel>();

    const selectedGroup = configuration.likertAnswerGroups?.filter((group) => group.id === likertAnswerGroupId)[0];

    return (
        <Stack spacing={3}>
            {configuration.likertAnswerGroups ? (
                <FormikDropdown
                    label={UIStrings.LikertAnswerGroup}
                    name="likertAnswerGroupId"
                    options={likertAnswerGroupDropdownOptions}
                    placeholder={UIStrings.SelectLikertAnswerGroupPlaceholder}
                />
            ) : (
                <Typography variant="body1" color={theme.palette.error.main}>
                    {UIStrings.NoLikertAnswerGroupAvailable}
                </Typography>
            )}
            {selectedGroup && (
                <Stack direction="row" spacing={1}>
                    {selectedGroup.answers.map((answer) => (
                        <FormControlLabel
                            key={answer.id}
                            control={<Radio />}
                            label={answer.content}
                            labelPlacement="top"
                            disabled
                        />
                    ))}
                </Stack>
            )}
        </Stack>
    );
};

export default LikertAnswerContainer;
