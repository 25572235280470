export const ProcessIdle = 'idle';
export const ProcessRunning = 'running';
export const ProcessFulfilled = 'fulfilled';
export const ProcessError = 'error';
export const ProcessConflict = 'conflict';

export type ProcessState =
    | typeof ProcessIdle
    | typeof ProcessRunning
    | typeof ProcessFulfilled
    | typeof ProcessError
    | typeof ProcessConflict;
export interface Processable {
    processState: ProcessState;
}

export const DefaultProcessable: Processable = {
    processState: ProcessIdle,
};
