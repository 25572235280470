import React, { FC } from 'react';

import {
    InputLabel,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Theme,
    Typography,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/styles';
import AnswerData from '../../../../Models/AnswerData';
import GreenCheckbox from '../../../../Components/Custom/GreenCheckbox';
import UIStrings from '../../../../Utils/UIStrings';

interface Props {
    answers: AnswerData[];
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
}));

const MultipleChoiceAnswerInfos: FC<Props> = ({ answers }: Props) => {
    const classes = useStyles();
    return (
        <div>
            <InputLabel>
                <Typography>{UIStrings.Answers}</Typography>
            </InputLabel>
            <List className={classes.root}>
                {answers.map((answer) => (
                    <ListItem key={answer.id}>
                        <ListItemText>
                            <Typography fontWeight="800">{answer.content}</Typography>
                        </ListItemText>
                        <ListItemSecondaryAction>
                            {answer.isCorrect && <GreenCheckbox checked={answer.isCorrect} disabled />}
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>
        </div>
    );
};

export default MultipleChoiceAnswerInfos;
