import { useField } from 'formik';
import React, { FC } from 'react';

type FormikDropdownProps = React.InputHTMLAttributes<HTMLInputElement> & {
    name: string;
};

const FormikFileSelector: FC<FormikDropdownProps> = ({ name, accept }: FormikDropdownProps) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [field, meta, helpers] = useField(name);
    return (
        <input
            type="file"
            name={name}
            id="avatar"
            accept={accept}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (event.currentTarget.files) {
                    helpers.setValue(event.currentTarget.files[0]);
                }
            }}
        />
    );
};

export default FormikFileSelector;
