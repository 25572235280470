import React, { FC } from 'react';
import { QuestionData } from '../../../../Models/QuestionData';
import UIStrings from '../../../../Utils/UIStrings';
import FormikTextField from '../../../../Components/FormikFormComponents/FormikTextField';

import { QuestionCreationForm } from '../../Add/Components/QuestionCreationForm';
import { createExactQuestionValidationSchema } from '../../Add/Components/QuestionValidationSchema';
import DifficultySection from '../../Add/Components/DifficultySelector';
import { QuestionFormModel, mapQuestionDataToQuestionFormModel } from '../../../../Models/QuestionFormModel';

interface Props {
    question: QuestionData;
}

const ExactQuestionUpdateForm: FC<Props> = ({ question }: Props) => {
    const upsertQuestion: QuestionFormModel = mapQuestionDataToQuestionFormModel(question);
    const validationSchema = createExactQuestionValidationSchema();

    return (
        <QuestionCreationForm
            initialValues={upsertQuestion}
            validationSchema={validationSchema}
            answerSectionComponent={
                <FormikTextField fullWidth name="answers[0].content" type="TextArea" label={UIStrings.CorrectAnswer} />
            }
            additionalOptionsSectionComponent={<DifficultySection />}
        />
    );
};

export default ExactQuestionUpdateForm;
