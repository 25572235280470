import React from 'react';
import { Box, Container } from '@material-ui/core';
import PageTitle from './PageTitle';

interface Props {
    title?: string;
}

const ErrorPage = ({ title }: Props) => (
    <Container>
        <Box sx={{ marginTop: 4 }}>{title && <PageTitle title={title} />}</Box>
    </Container>
);

export default ErrorPage;
