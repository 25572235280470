import { get } from './HttpUtils';
import { Configuration, DefaultConfiguration } from '../Models/Configuration';
import ClientBase from './ClientBase';

export default class ConfigurationClient extends ClientBase {
    async getConfiguration(): Promise<Configuration> {
        const accessToken = await this.getAccessTokenSilently();
        const result = await get<Configuration>({
            path: super.addHost('configuration'),
            accessToken,
        });

        if (result.ok && result.body) {
            return result.body;
        } else {
            return DefaultConfiguration;
        }
    }
}
