import { Button } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router';
import UIStrings from '../../Utils/UIStrings';

function NavigateBackButton() {
    const history = useHistory();

    const backHandler = () => {
        history.goBack();
    };
    return (
        <Button variant="outlined" onClick={backHandler}>
            {UIStrings.Back}
        </Button>
    );
}

export default NavigateBackButton;
