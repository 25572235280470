import React from 'react';
import { useField } from 'formik';
import { FormControl, TextField, TextFieldProps } from '@material-ui/core';

type FormikTextFieldProps = TextFieldProps & { name: string };

const FormikTextField = ({ name, ...props }: FormikTextFieldProps) => {
    const [field, meta, helpers] = useField(name);
    return (
        <FormControl fullWidth error={meta.error != null} variant="outlined">
            <TextField
                {...props}
                variant="outlined"
                value={field.value}
                error={meta.error != null}
                helperText={meta.touched ? meta.error : ''}
                onChange={(event) => {
                    helpers.setValue(event.target.value);
                    if (props.onChange) {
                        props.onChange(event);
                    }
                }}
            />
        </FormControl>
    );
};

export default FormikTextField;
