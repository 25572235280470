import React from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Formik, FormikHelpers } from 'formik';
import Stack from '@material-ui/core/Stack';
import { useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Button, Card, CardContent, CardHeader, Container, Divider } from '@material-ui/core';
import UIStrings from '../../../Utils/UIStrings';
import { RootState, useAppDispatch } from '../../../app/store';
import { addQuestionTopic } from '../questionTopics.slice';
import { QuestionTopicFormValues } from '../models/QuestionTopicFormValues';
import QuestionTopicAddSection from './QuestionTopicAddSection';
import useStyles from '../styles';
import ConfirmationBlockDialog from '../../../Components/ConfirmationDialog/ConfirmationBlockDialog';
import NavigateBackButton from '../../../Components/Custom/NavigateBackButton';
import { showToaster } from '../../../Components/Notifications/toaster.slice';
import { DefaultOptionValue } from '../../../Models/OptionValue';

function QuestionTopicAddPage(): JSX.Element {
    const classes = useStyles();

    const history = useHistory();
    const dispatch = useAppDispatch();
    const testTypeTopicMaps = useSelector((state: RootState) => state.configuration.testTypeTopicMaps);
    const questionTopics = useSelector((state: RootState) => state.configuration.questionTopics);
    const { getAccessTokenSilently } = useAuth0();

    let questionTopic = useSelector((state: RootState) => state.questionTopic);
    if (questionTopic.length === 0) {
        questionTopic = JSON.parse(localStorage.getItem('questionTopic') || '');
    }
    const initialValues: QuestionTopicFormValues = {
        testType: '',
        questionTopic: questionTopic[0] ?? undefined,
        questionSubtopic1: questionTopic[1] ?? undefined,
        questionSubtopic2: questionTopic[2] ?? undefined,
    };

    const submitCallback = async (
        values: QuestionTopicFormValues,
        { setSubmitting, resetForm, setFieldError }: FormikHelpers<any>,
    ) => {
        const fieldNames = getErrorFieldNames(values);
        fieldNames.forEach((fieldName) => setFieldError(fieldName, UIStrings.Required));
        if (fieldNames.length > 0) {
            return;
        }

        const response = await dispatch(addQuestionTopic({ getAccessTokenSilently, values }));
        setSubmitting(false);
        if (addQuestionTopic.fulfilled.match(response)) {
            resetForm();
            dispatch(showToaster(UIStrings.QuestionTopicSavedSuccess, 'success'));
            history.push(`/question-topics/`);
        } else {
            const message = response?.error?.message || UIStrings.WriteQuestionTopicError;
            dispatch(showToaster(message, 'error'));
        }
    };

    const isNullOrWhiteSpace = (value?: string): boolean => {
        return value == null || value === '' || value === ' ';
    };

    const getErrorFieldNames = (values: QuestionTopicFormValues): string[] => {
        const fieldNames: string[] = [];
        if (isNullOrWhiteSpace(values.testType)) {
            fieldNames.push('questionType');
        }

        if (
            values.questionTopic != null &&
            (values.questionTopic === DefaultOptionValue || isNullOrWhiteSpace(values.questionTopic.name))
        ) {
            fieldNames.push('questionTopic');
        }

        if (
            values.questionSubtopic1 != null &&
            (values.questionSubtopic1 === DefaultOptionValue || isNullOrWhiteSpace(values.questionSubtopic1.name))
        ) {
            fieldNames.push('questionSubtopic1');
        }

        if (
            values.questionSubtopic2 != null &&
            (values.questionSubtopic2 === DefaultOptionValue || isNullOrWhiteSpace(values.questionSubtopic2.name))
        ) {
            fieldNames.push('questionSubtopic2.name');
        }

        return fieldNames;
    };

    const title = `${UIStrings.Create} ${UIStrings.QuestionTopic}`;

    return (
        <Box sx={{ padding: 3 }}>
            <Container maxWidth="sm">
                <Card>
                    <CardHeader title={title} />
                    <Divider />
                    <CardContent>
                        <Formik className={classes.root} initialValues={initialValues} onSubmit={submitCallback}>
                            {(formik) => (
                                <Form>
                                    <ConfirmationBlockDialog
                                        message={UIStrings.ChangesWouldBeLostWarning}
                                        formik={formik}
                                    />
                                    <QuestionTopicAddSection topicTree={questionTopics} />
                                    <Divider classes={{ root: classes.actionsDivider }} />
                                    <Stack direction="row" justifyContent="space-around">
                                        <Button variant="contained" color="primary" type="submit">
                                            {UIStrings.Submit}
                                        </Button>
                                        <NavigateBackButton />
                                    </Stack>
                                </Form>
                            )}
                        </Formik>
                    </CardContent>
                </Card>
            </Container>
        </Box>
    );
}

export default QuestionTopicAddPage;
