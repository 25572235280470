import React, { FC, useEffect, useState } from 'react';
import { useField } from 'formik';
import { InputLabel } from '@material-ui/core';
import FilePreview from '../Styled/FilePreview';

interface Props {
    fileValueName: string;
    blobURIValueName: string;
    fileMimeType?: string;
    uri?: string;
    removeImageCallback?: () => void;
}

const FormikThumb: FC<Props> = ({ fileValueName, blobURIValueName, fileMimeType, uri, removeImageCallback }: Props) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [fileValue, fileMeta, fileHelpers] = useField<File | undefined>(fileValueName);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [blobValue, blobMeta, blobHelpers] = useField<string | undefined>(blobURIValueName);
    const [loading, setLoading] = useState(true);
    const [thumb, setThumb] = useState<string | undefined>(uri);
    const [mimeType, setMimeType] = useState<string | undefined>(fileMimeType);

    useEffect(() => {
        const reader = new FileReader();
        reader.onloadend = () => {
            if (typeof reader.result === 'string') {
                setThumb(reader.result);
                setMimeType(fileValue.value?.type);
                setLoading(false);
            }
        };

        if (fileValue.value) {
            reader.readAsDataURL(fileValue.value);
        }
    }, [fileValue.value]);

    const removeFile = () => {
        setThumb(undefined);
        fileHelpers.setValue(undefined);
        blobHelpers.setValue(undefined);
        const fileInput = document.getElementById('avatar') as HTMLInputElement;
        if (fileInput) {
            fileInput.value = '';
        }
    };

    return (
        <>
            {fileValue.value && loading && <InputLabel>loading</InputLabel>}
            {(fileValue.value || thumb) && (
                <FilePreview content={thumb} fileMimeType={mimeType} removeFile={removeFile} />
            )}
        </>
    );
};

export default FormikThumb;
