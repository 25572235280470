import { Checkbox } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { CheckboxProps } from '@material-ui/core/Checkbox/Checkbox';
import React from 'react';
import { withStyles } from '@material-ui/styles';

const GreenCheckbox = withStyles({
    root: {
        '&$checked': {
            color: green[600],
        },
        '&disabled': {
            color: green[600],
        },
    },
    checked: {},
})((props: CheckboxProps) => <Checkbox {...props} />);

export default GreenCheckbox;
