import { OptionValue } from './OptionValue';

interface UserProfile {
    userId: string;
    name: string;
    email: string;
}

export function mapUserProfilesToOptionValues(profiles: UserProfile[]): OptionValue[] {
    return profiles.map((item) => {
        return {
            id: item.userId,
            name: `${item.name} (${item.email})`,
        };
    });
}

export default UserProfile;
