import { DataGrid, GridCellParams, GridColDef, GridValueGetterParams } from '@material-ui/data-grid';
import { Button, Card, CardContent } from '@material-ui/core';
import React, { useState } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';
import { DefaultOptionValue, OptionValue } from '../../../Models/OptionValue';
import UIStrings from '../../../Utils/UIStrings';
import QuestionTopicTreeNode from '../../../Models/QuestionTopicTreeNode';
import ConfirmationDialogRaw from '../../../Components/ConfirmationDialog/confirmationDialogRaw';
import { RootState, useAppDispatch } from '../../../app/store';
import { deleteQuestionTopic } from '../questionTopics.slice';
import useStyles from '../styles';
import { setQuestionTopicValues } from '../questionTopic.slice';
import { ProcessRunning } from '../../../Models/processable';
import { showToaster } from '../../../Components/Notifications/toaster.slice';
import { getErrorMessage } from '../../../app/store.utils';

function QuestionTopicListResults(): JSX.Element {
    const classes = useStyles();
    const dispatch = useAppDispatch();

    const [topicId, setTopicId] = useState<number>(0);

    const { getAccessTokenSilently } = useAuth0();

    const history = useHistory();

    const testTypeTopicMaps = useSelector((state: RootState) => state.configuration.testTypeTopicMaps);
    const questionTopics = useSelector((state: RootState) => state.configuration.questionTopics);
    const processState = useSelector((state: RootState) => state.configuration.processState);

    const [open, setOpen] = useState(false);
    const handleConfirm = async () => {
        setOpen(false);
        const result = await dispatch(deleteQuestionTopic({ getAccessTokenSilently, topicId }));
        if (!deleteQuestionTopic.fulfilled.match(result)) {
            const message = getErrorMessage(result.error);
            dispatch(showToaster(message, 'error'));
            return;
        }
        history.push(`/question-topics/`);
    };

    const getOptionValue = (params: GridValueGetterParams): OptionValue | undefined => {
        let result: OptionValue | undefined;
        for (let i = 0; i < 3; i++) {
            const value = params.row[i] as unknown as OptionValue;
            if (value) {
                result = value;
            }
        }

        return result;
    };

    const getPath = (params: GridValueGetterParams): string => {
        let path = '';

        for (let i = 0; i < 3; i++) {
            const value = params.row[i] as unknown as OptionValue;
            if (value) {
                path += `${value.name}\\`;
            }
        }

        return path.substring(0, path.length - 1);
    };

    const columns: GridColDef[] = [
        {
            field: 'id',
            width: 75,
            valueGetter: (params: GridValueGetterParams) => {
                const value = getOptionValue(params);

                return value?.id || 0;
            },
        },
        {
            field: 'test type',
            width: 200,
            valueGetter: (params: GridValueGetterParams) => {
                const path = getPath(params);
                const testTypeTopicMap = testTypeTopicMaps.find((x) => x.questionTopicPath === path);

                return testTypeTopicMap?.testType || '';
            },
        },
        {
            field: 'name',
            width: 200,
            valueGetter: (params: GridValueGetterParams) => {
                const value = getOptionValue(params);

                return value?.name || '';
            },
        },
        {
            field: 'path',
            width: 500,
            valueGetter: (params: GridValueGetterParams) => {
                return getPath(params);
            },
        },
        {
            field: '',
            headerName: 'action',
            // disableClickEventBubbling: true,
            width: 200,
            renderCell: (params: GridCellParams) => {
                return (
                    <>
                        <Button
                            color="primary"
                            className={classes.button}
                            startIcon={<EditIcon />}
                            onClick={() => {
                                const optionValues: OptionValue[] = [DefaultOptionValue];

                                for (let i = 0; i < 3; i++) {
                                    const optionValue = params.row[i] as unknown as OptionValue;
                                    if (optionValue) {
                                        optionValues.push(optionValue);
                                    }
                                }

                                const testTypeTopicMap = testTypeTopicMaps.find(
                                    (x) => x.questionTopicPath === optionValues[1].name,
                                );
                                optionValues[0] = {
                                    id: `${testTypeTopicMap?.id}`,
                                    name: `${testTypeTopicMap?.testType}`,
                                };

                                dispatch(setQuestionTopicValues(optionValues));
                                history.push(`/question-topics/edit/${params.getValue(params.id, 'id')}`);
                            }}
                        >
                            {UIStrings.Edit}
                        </Button>
                        |
                        <Button
                            color="secondary"
                            className={classes.button}
                            startIcon={<DeleteIcon />}
                            onClick={() => {
                                setTopicId(Number(params.getValue(params.id, 'id')));
                                setOpen(true);
                            }}
                        >
                            {UIStrings.Delete}
                        </Button>
                    </>
                );
            },
        },
    ];

    const traverse = (node: QuestionTopicTreeNode, path: OptionValue[] = [], result: OptionValue[][] = []) => {
        result.push(path.concat({ id: `${node.id}`, name: node.name }));

        for (const child of node?.children || []) {
            traverse(child, path.concat({ id: `${node.id}`, name: node.name }), result);
        }
        return result;
    };

    const rows: OptionValue[][] = [];
    questionTopics.forEach((node) => {
        rows.push(...traverse(node));
    });

    let key = 1;

    return (
        <>
            <Card>
                <CardContent>
                    <DataGrid
                        getRowId={(row) => {
                            key += 1;
                            return key;
                        }}
                        columns={columns}
                        rows={rows}
                        autoHeight
                        hideFooterPagination
                        hideFooter
                        loading={processState === ProcessRunning}
                    />
                </CardContent>
            </Card>

            <ConfirmationDialogRaw
                classes={{
                    paper: classes.paper,
                }}
                id="confirmation"
                approveText={UIStrings.Ok}
                declineText={UIStrings.Cancel}
                message={UIStrings.AreYouSure}
                keepMounted
                open={open}
                onConfirm={handleConfirm}
                onCancel={() => setOpen(false)}
            />
        </>
    );
}

export default QuestionTopicListResults;
