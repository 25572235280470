import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import { ApplicationInsightsSettings } from '../AppSettings';

const browserHistory = createBrowserHistory();
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        disableFetchTracking: false,
        // enableCorsCorrelation: true, this line cause redirect loop
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
        correlationHeaderExcludedDomains: ['myapp.azurewebsites.net', '*.queue.core.windows.net'],
        instrumentationKey: ApplicationInsightsSettings.key,
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory },
        },
    },
});

appInsights.loadAppInsights();
const telemetryInitializer = (envelope: any) => {
    // eslint-disable-next-line no-param-reassign
    envelope.tags['ai.cloud.role'] = ApplicationInsightsSettings.cloudRole;
};
appInsights.addTelemetryInitializer(telemetryInitializer);

export { reactPlugin, appInsights };
