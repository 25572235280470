import { TestType, QuestionDifficulty, Status, QuestionType } from './Configuration';
import AnswerData from './AnswerData';
import { QuestionData } from './QuestionData';
import { reverseTopicTree } from './QuestionTopicData';

export interface QuestionFormModel {
    id: string;
    question: string;
    testType: TestType;
    questionDifficulty: QuestionDifficulty;
    questionTopic: string;
    questionSubtopic1: string;
    questionSubtopic2: string;
    questionInfo?: string;
    blobFileURI?: string;
    fileMimeType?: string;
    originalFilename?: string;
    creatorId: string;
    file?: File;
    verifierId: string;
    status: Status;
    answers: AnswerData[];
    rowVersion: string;
    questionType: QuestionType;
}

export interface LikertQuestionFormModel extends QuestionFormModel {
    likertAnswerGroupId: string;
    positivelyCoded: boolean;
}

export function mapQuestionDataToQuestionFormModel(input: QuestionData): QuestionFormModel {
    const rootTopic = reverseTopicTree(input.topic);
    const result: QuestionFormModel = {
        id: input.id,
        question: input.question,
        testType: input.testType,
        questionDifficulty: input.questionDifficulty,
        blobFileURI: input.blobFileURI,
        fileMimeType: input.fileMimeType,
        originalFilename: input.originalFilename,
        creatorId: input.creatorId,
        verifierId: input.verifierId,
        status: input.status,
        answers: input.answers,
        rowVersion: input.rowVersion,
        questionType: input.questionType,
        questionInfo: input.questionInfo,
        questionTopic: rootTopic.id.toString() ?? '',
        questionSubtopic1: rootTopic.child?.id.toString() ?? '',
        questionSubtopic2: rootTopic.child?.child?.id.toString() ?? '',
    };

    return result;
}

export function mapQuestionDataToLikertQuestionFormModel(question: QuestionData): LikertQuestionFormModel {
    const formModel: QuestionFormModel = mapQuestionDataToQuestionFormModel(question);
    const result: LikertQuestionFormModel = {
        ...formModel,
        likertAnswerGroupId: question.likertAnswerGroupId,
        positivelyCoded: question.positivelyCoded,
    };
    return result;
}
