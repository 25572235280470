import React from 'react';
import { FormControl, InputLabel, Typography } from '@material-ui/core';

interface LabeledTextProps {
    label?: string;
    text?: string;
    textColor?: string;
}
const LabeledText = ({ label, text, textColor }: LabeledTextProps) => {
    return (
        <div>
            {label && (
                <InputLabel>
                    <Typography>{label}</Typography>
                </InputLabel>
            )}
            {text && (
                <FormControl>
                    <Typography fontWeight="800" color={textColor}>
                        {text}
                    </Typography>
                </FormControl>
            )}
        </div>
    );
};

export default LabeledText;
