import React, { FC } from 'react';
import { QuestionData } from '../../../../Models/QuestionData';
import MultipleChoiceAnswerContainer from '../../Add/Components/MultipleChoiceAnswerContainer';
import { QuestionCreationForm } from '../../Add/Components/QuestionCreationForm';
import { createMultipleChoiceQuestionValidationSchema } from '../../Add/Components/QuestionValidationSchema';
import DifficultySection from '../../Add/Components/DifficultySelector';
import { mapQuestionDataToQuestionFormModel, QuestionFormModel } from '../../../../Models/QuestionFormModel';

interface Props {
    question: QuestionData;
}

const MultipleChoiceQuestionUpdateForm: FC<Props> = ({ question }: Props) => {
    const upsertQuestion: QuestionFormModel = mapQuestionDataToQuestionFormModel(question);
    const validationSchema = createMultipleChoiceQuestionValidationSchema();

    return (
        <QuestionCreationForm
            initialValues={upsertQuestion}
            validationSchema={validationSchema}
            answerSectionComponent={<MultipleChoiceAnswerContainer />}
            additionalOptionsSectionComponent={<DifficultySection />}
        />
    );
};

export default MultipleChoiceQuestionUpdateForm;
