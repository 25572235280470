/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC } from 'react';

import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/styles';
import UIStrings from '../../Utils/UIStrings';

interface Props {
    content?: string;
    fileMimeType?: string;
    removeFile?: () => void;
}

const useStyles = makeStyles({
    removeButtonClass: {
        position: 'absolute',
        top: '5px',
        right: '5px',
        color: '#FF0000',
        cursor: 'pointer',
        opacity: '0.8',
        borderRadius: '50%',
        zIndex: 1,
        '&:hover': {
            opacity: '1.2',
        },
    },
    imageContainerClass: {
        position: 'relative',
        display: 'inline-block',
        width: '100%',
        height: '100%',
    },
});

const FilePreview: FC<Props> = ({ content, fileMimeType, removeFile }: Props) => {
    const classes = useStyles();

    const removeHandler = (event: React.MouseEvent) => {
        if (removeFile) {
            removeFile();
        }
    };

    return (
        <div className={classes.imageContainerClass}>
            {removeFile && (
                <IconButton onClick={removeHandler} className={classes.removeButtonClass.toString()} size="small">
                    <DeleteIcon />
                </IconButton>
            )}
            {fileMimeType && (
                <>
                    {fileMimeType.startsWith('image') && <img src={content} alt="" width="100%" height="100%" />}
                    {fileMimeType.startsWith('video') && (
                        <video src={content} controls width="100%">
                            {UIStrings.BrowserDoesNotSupportVideo}
                        </video>
                    )}
                    {fileMimeType.startsWith('audio') && (
                        <audio src={content} controls>
                            {UIStrings.BrowserDoesNotSupportVideo}
                        </audio>
                    )}
                </>
            )}
        </div>
    );
};

export default FilePreview;
