import { Alert, Snackbar, SnackbarCloseReason } from '@material-ui/core';
import React, { SyntheticEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeToaster } from './toaster.slice';
import { RootState } from '../../app/store';

function Toaster() {
    const { open, message, severity } = useSelector((state: RootState) => state.toaster);
    const dispatch = useDispatch();

    const closeSnackbarHandler = (event: SyntheticEvent, reason: SnackbarCloseReason) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(closeToaster());
    };
    const closeAlertHandler = (event: SyntheticEvent<Element, Event>) => {
        closeSnackbarHandler(event, 'timeout');
    };

    return (
        <Snackbar
            open={open}
            autoHideDuration={3000}
            onClose={closeSnackbarHandler}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
            <Alert variant="filled" severity={severity} onClose={closeAlertHandler}>
                {message}
            </Alert>
        </Snackbar>
    );
}

export default Toaster;
