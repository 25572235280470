import React, { FC } from 'react';
import { QuestionData } from '../../../../Models/QuestionData';
import LikertAnswerInfo from './LikertAnswerInfo';
import QuestionInfo from './QuestionInfo';

interface Props {
    question: QuestionData;
}
const LikertQuestionInfo: FC<Props> = ({ question }: Props) => (
    <QuestionInfo
        question={question}
        answerComponent={<LikertAnswerInfo likertGroupId={question.likertAnswerGroupId} />}
    />
);

export default LikertQuestionInfo;
