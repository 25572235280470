import React from 'react';
import { Box, Container } from '@material-ui/core';
import QuestionTopicListToolbar from './QuestionTopicListToolbar';
import QuestionTopicListResults from './QuestionTopicListResults';
import PageTitle from '../../../Components/PageTitle';
import UIStrings from '../../../Utils/UIStrings';

function QuestionTopicListPage(): JSX.Element {
    return (
        <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}>
            <Container maxWidth={false}>
                <Box>
                    <QuestionTopicListToolbar />
                </Box>
                <PageTitle title={UIStrings.QuestionTopicsPageTitle} />
                <Box sx={{ pt: 3 }}>
                    <QuestionTopicListResults />
                </Box>
            </Container>
        </Box>
    );
}

export default QuestionTopicListPage;
