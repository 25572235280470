import { Box, Button } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import { setQuestionTopicValues } from '../questionTopic.slice';
import UIStrings from '../../../Utils/UIStrings';
import useStyles from '../styles';

function QuestionTopicListToolbar(): JSX.Element {
    const history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();

    return (
        <Box sx={{ display: 'flex', justifyContent: 'flex-star' }}>
            <Button
                color="primary"
                variant="contained"
                startIcon={<AddIcon />}
                className={classes.button}
                onClick={() => {
                    dispatch(setQuestionTopicValues([]));
                    history.push(`/question-topics/add`);
                }}
            >
                {UIStrings.AddQuestionTopic}
            </Button>
        </Box>
    );
}

export default QuestionTopicListToolbar;
