import { useAuth0 } from '@auth0/auth0-react';
import React, { FC, Fragment, ReactNode } from 'react';
import { claimNames } from '../AppSettings';

interface Props {
    allowedRoles: string[];
    accessAllowedComponent: ReactNode;
    accessRestrictedComponent?: ReactNode;
    accessPredicate?: () => boolean;
}

export const RoleProtectedComponent: FC<Props> = ({
    allowedRoles,
    accessAllowedComponent,
    accessRestrictedComponent,
    accessPredicate = () => true,
}) => {
    const { user } = useAuth0();

    if (user && user[claimNames.role] && isUserRoleAllowed(allowedRoles, user[claimNames.role]) && accessPredicate()) {
        return <>{accessAllowedComponent}</>;
    } else {
        return <>{accessRestrictedComponent}</>;
    }
};

export function isUserRoleAllowed(allowedRoles: string[], userRoles: string[]) {
    const filteredArray = allowedRoles.filter((role) => userRoles.includes(role));
    return filteredArray.length > 0;
}
