import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import StyledEngineProvider from '@material-ui/core/StyledEngineProvider';

import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import Auth0ProviderWithHistory from './Auth/Auth0ProviderWithHistory';
import App from './app/App';
import store from './app/store';
import Toaster from './Components/Notifications/Toaster';

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <StyledEngineProvider injectFirst>
                <Auth0ProviderWithHistory>
                    <Provider store={store}>
                        <App />
                        <Toaster />
                    </Provider>
                </Auth0ProviderWithHistory>
            </StyledEngineProvider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root'),
);

if (process.env.NODE_ENV !== 'production') {
    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    // eslint-disable-next-line no-console
    reportWebVitals(console.log);
}
