/* eslint-disable @typescript-eslint/lines-between-class-members */
export default class UIStrings {
    static readonly Back = 'Back';
    static readonly AddQuestion = 'Add Question';
    static readonly EditQuestion = 'Edit Question';
    static readonly RejectQuestion = 'Reject Question';
    static readonly ApproveQuestion = 'Approve Question';
    static readonly SingIn = 'Sign in';
    static readonly SingOut = 'Sign out';

    static readonly QuestionsPageTitle = 'Questions';
    static readonly QuestionTopicsPageTitle = 'Question topics';
    static readonly QuestionInfoPageTitle = 'Question info';
    static readonly EditQuestionPageTitle = 'Edit question with id: ';
    static readonly CreateQuestionPageTitle = 'Create question';

    static readonly QuestionTypes = 'Question types';
    static readonly ChoiceAnswerType = 'Choice';
    static readonly ExactAnswerType = 'Exact';
    static readonly LikertAnswerType = 'Likert';

    static readonly TestType = 'Test type';

    static readonly LikertAnswerGroup = 'Likert Answer Group';
    static readonly QuestionTopic = 'Question topic'; // Finance, It ...
    static readonly QuestionSubTopic1 = 'Question subtopic 1';
    static readonly QuestionSubTopic2 = 'Question subtopic 2';
    static readonly ContentVerifier = 'Content verifier';
    static readonly RejectionComment = 'Rejection comment';

    static readonly IdColumnTitle = 'Id';
    static readonly Status = 'Status';
    static readonly Topic = 'Topic';
    static readonly QuestionType = 'Question type';
    static readonly Question = 'Question';
    static readonly Questions = 'Questions';
    static readonly QuestionInfo = 'Question Info';
    static readonly Answers = 'Answers';
    static readonly CreatorHistoryColumnTitle = 'Creator';
    static readonly CreationDate = 'Creation Date';
    static readonly Verifier = 'Verifier';
    static readonly VerificationDate = 'Verification Date';
    static readonly LikertAnswerGroupLabel = 'Likert answer group';
    static readonly NoLikertAnswerGroupAvailable = 'There are no available likert answer groups.';

    static readonly QuestionIdQuestionMetadata = 'Question Id';
    static readonly CreatedByQuestionMetadata = 'Created By';

    static readonly SearchPlaceholder = 'Search...';
    static readonly SearchQuestionPlaceholder = 'Search question...';
    static readonly SelectQuestionTopicPlaceholder = 'Select question topic';
    static readonly SelectQuestionSubTopic1Placeholder = 'Select question subtopic 1';
    static readonly SelectQuestionSubTopic2Placeholder = 'Select question subtopic 2';
    static readonly SelectContentVerifierPlaceholder = 'Select content verifier';
    static readonly SelectTestTypePlaceholder = 'Select test type';
    static readonly SelectLikertAnswerGroupPlaceholder = 'Select likert answer group';
    static readonly SelectQuestionDifficultyPlaceholder = 'Select question difficulty';
    static readonly OptionPlaceholder = 'Option';

    static readonly AddAnswerOptionButton = 'Add Answer Option';
    static readonly SubmitYourQuestionButton = 'Submit Your Question';
    static readonly ReloadYourQuestionButton = 'Reload Your Question';

    static readonly Required = 'Required';
    static readonly OneExactAnswer = 'There can only be one correct answer';
    static readonly MinimumFourAnswersValidationMessage = 'There must be at least 4 answer options';
    static readonly MaximumFiveAnswersValidationMessage = 'There must be at most 5 answer options';
    static readonly AtLeastOneAnswerMustBeCorrectValidationMessage = 'At least one answer must be correct';
    static readonly AtLeastOneAnswerMustBeIncorrectValidationMessage = 'At least one answer must be incorrect';
    static readonly AnswerExceedsMaximumLength = 'Some answers exceed maximum possible length';

    static readonly AdditionalInformation = 'Additional information';

    static readonly CorrectAnswer = 'Correct answer';

    static readonly QuestionDifficulty = 'Question Difficulty';
    static readonly PositivelyCoded = 'Positively Coded';

    static readonly MetaData = 'Question Metadata';
    static readonly Comment = 'Comment';

    static readonly UnauthorizedAccess = 'You do not have permission to view this page';
    static readonly PleaseSignIn = 'Please sign in';
    static readonly NotAuthorized = 'Not Authorized';
    static readonly PageNotFount = 'Page Not Found';

    static readonly LoadMore = 'Load more';

    static readonly AreYouSure = 'Are you sure?';
    static readonly Ok = 'OK';
    static readonly Cancel = 'Cancel';
    static readonly ChangesWouldBeLostWarning = 'Changes will be lost if you leave. Continue?';
    static readonly Submit = 'Submit';
    static readonly AddQuestionTopic = 'Add Question Topic';
    static readonly Create = 'Create';
    static readonly Edit = 'Edit';
    static readonly Delete = 'Delete';
    static readonly Confirmation = 'Confirmation';
    static readonly QuestionTopics = 'Question Topics';
    static readonly Option = 'Option';

    static readonly QuestionSavedSuccess = 'Question saved!';
    static readonly QuestionReloadSuccess = 'Question reloaded!';
    static readonly QuestionTopicSavedSuccess = 'Question topic saved!';

    static readonly ReadQuestionError = 'Error occurred while reading question from server';
    static readonly WriteQuestionError = 'Error occurred while trying to write question to server';
    static readonly ReadQuestionListError = 'Error loading questions list';
    static readonly ApproveQuestionError = 'Error occurred while trying to approve question';
    static readonly RejectQuestionError = 'Error occurred while trying to reject question';

    static readonly TopicsReadError = 'Error occurred while reading topics from server';
    static readonly WriteQuestionTopicError = 'Error occurred while trying to write question topic to server!';
    static readonly DeleteTopicError = 'Error occurred while trying to delete topic';
    static readonly Actions = 'Actions';

    static readonly AddTopic = 'Add topic';
    static readonly Logout = 'Logout';
    static readonly EmptyTestType = 'Empty test type';

    static readonly BrowserDoesNotSupportVideo = 'Your browser does not support the video tag.';
}
